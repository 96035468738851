import React from "react";
import HeroCarousel from "react-hero-carousel";
import forside2 from "../../assets/forside2.jpg";
import forside1 from "../../assets/forside1.png";
import forside4 from "../../assets/forside4.jpg";
import forside5 from "../../assets/forside5.jpg";
import Logo from "../../assets/homePage-logo.png";
import First from "../../assets/homePage00.png";
import Helsinki from "../../assets/homePage01.png";
import Norefjell from "../../assets/homePage02.png";
import Martin from "../../assets/blog/blog01.png";
import Bjarne from "../../assets/blog/blog02.png";
import { TiArrowRight } from "react-icons/ti";
import "./home2.css";

const Home2 = () => {
  return (
    <>
      <div className="loadmore__home">
        <div className="loadmore__home-container">
          <div className="home_container_wrapper">
            <img src={forside1} alt="Hindsgavl" />
            <HeroCarousel interval={5000}>
              <img src={forside1} alt="Hindsgavl" />
              <img src={forside2} alt="Hindsgavl" />

              <img src={forside4} alt="Hindsgavl" />
              <img src={forside5} alt="Hindsgavl" />
            </HeroCarousel>
          </div>
          <div className="maxWidth">
            <div className="loadmore__home-container_logo section__padding">
              <img src={Logo} alt="Logo" />
              <div className="loadmore__home-container_logo-text">
                <h1>4ᵗʰ NCGE</h1>
                <h3>
                  Nordic Congress on <br /> Gynaecological Endoscopy
                </h3>
                <h1>6ᵗʰ NCE</h1>
                <h3>
                  Nordic Conference on <br /> Endometriosis
                </h3>
                <p>1 - 3 June 2023</p>
                <p>
                  NCGE-NCE2023 is approved by Ethical Medtech, link:
                  <a
                    href="https://www.ethicalmedtech.eu/medtech-apps/cvs/view-event/EMT32723"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EthicalMedtech
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="loadmore__home-content padding__inline padding__block">
          <div className="loadmore__home-content_first">
            <div className="loadmore__home-content_first-text">
              <h3>Welcome</h3>

              <p>
                The Nordic societies have found it optimal to merge the two
                congresses, Nordic Conference on Endometriosis (6th NCE) and
                Nordic Congress on Gynaecological Endoscopy/Minimal Invasive
                Gynecological Surgery (4th NCGE) from 2023, and we are proud to
                welcome you to the first merged congress on Funen, Denmark 1-3
                June 2023.
                <br />
                <br />
                The NCGE-NCE2023 Congress has high visibility within the medical
                and research community and is organized this time by the OUH
                Odense University Hospital. The Congress provides an optimal
                opportunity to learn about the latest progress. It will attract
                doctors and specialists and researchers within this area. A
                number of highly regarded Nordic lectures will participate in
                this Congress.
                <br />
                <br /> Except this Congress will also invite and encourage
                participants from other countries outside the Nordic countries.
                The Congress will be held at Hindsgavl in Middelfart, Funen,
                Denmark.
                <br />
                <br /> We look forward to welcome you in Middelfart.
                <br />
                <br /> On behalf of the Organizing Committee
              </p>
            </div>

            <div className="loadmore__home-content_first-text_image">
              <img src={First} alt="First" />
            </div>
          </div>
          <div className="loadmore__home-content_second">
            <h3>Past Events</h3>
            <div className="loadmore__home-content_second-text">
              <div className="loadmore__home-content_second-text_container">
                <img src={Helsinki} alt="Helsinki" />
                <div className="picture__text">
                  <h6>3rd Nordic Congress</h6>
                  <p>5 - 8 June 2019 / Helsinki, Finland</p>
                </div>
              </div>
              <div className="loadmore__home-content_second-text_container">
                <img src={Norefjell} alt="Norefjell" />
                <div className="picture__text">
                  <h6>2nd Nordic Congress</h6>
                  <p>8 - 12 March 2017 / Norefjell, Norway</p>
                </div>
              </div>
              <div className="see-more">
                <div className="fade-home" />
                <div className="see-more-inner">
                  <p>See more</p>
                  <TiArrowRight className="icon-home" />
                </div>
              </div>
            </div>
          </div>
          <div className="loadmore__home-content_third ">
            <div className="loadmore__home-content_third-text">
              <img src={Martin} alt="Martin" />
              <div className="loadmore__home-content_third-text_container">
                <div className="loadmore__home-content_third-text_container-white">
                  <h3>Martin Rudnicki</h3>
                  <p>
                    Congress President
                    <br />
                    <br />
                    Professor
                    <br /> Head of Minimal Invasive Gynecological Surgery
                    <br />
                    <br /> Department of Gynecology and Obstetrics
                    <br /> OUH Odense Odense University Hospital
                  </p>
                </div>
              </div>
            </div>
            <div className="loadmore__home-content_third-text">
              <img src={Bjarne} alt="Bjarne" />
              <div className="loadmore__home-content_third-text_container">
                <div className="loadmore__home-content_third-text_container-white">
                  <h3>Bjarne Rønde Kristensen</h3>
                  <p>
                    Chairman of the Organizing Committee
                    <br />
                    <br /> Chief Consulant, Associated Professor
                    <br />
                    Head of Studies, MD
                    <br />
                    <br />
                    Department of Gynecology and Obstetrics
                    <br /> OUH Odense Odense University Hospital
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home2;
