import React from "react";
import "./precongress.css";

const Precongress = () => {
  return (
    <div className="precongress section__padding">
      <div className="precongress__container ">
        <h1>Pre-congress Workshop</h1>
        <h2>
          Unfortunately, further registrations are closed as the maximum number
          of participants has been reached.
        </h2>
        <div className="precongress__container-content">
          <div className="precongress__container-content_text">
            <h3>Course 1</h3>
            <p className="precongress__cursive">
              The Nordic Basic Laparoscopic training course
            </p>
            <p>
              Tuesday 30 June 2023; 9:00-16:00 and Wednesday 31 June 2023;
              9:00-16:00.
            </p>
            <p className="precongress__weight">
              <br />
              NSGE laparoscopic surgery course in association with the Nordic
              Congress on Gynaecological Endoscopy
            </p>
            <p>
              <br />
              The course will include two days of training:
              <br /> Monday 29 May 2023 at 18:30, Get together evening.
              <br /> Tuesday 30 May 2023; 9:00-16:00, Day 1; see description
              below.
              <br /> Wednesday 31 May 2023; 9:00-16:00, Day 2, see description
              below.
            </p>
            <p className="precongress__weight">
              <br />
              Place
            </p>
            <p>
              OUH Odense University Hospital, Entrance 50, conference room
              010-00-026 - look for signs.
            </p>
            <p>
              <br />
              Download the programme here:&nbsp;
              <a
                className="PrecongressDownloadLink"
                href="/files/Course1.pdf"
                target="_blank"
                download={"Course 1-lap. surgery- 30. - 31-05-2023.pdf"}
              >
                The Nordic Basic Laparoscopic training course
              </a>
            </p>
          </div>
          <div className="precongress__container-content_text">
            <h3>Course 1</h3>
            <p className="precongress__cursive">Myomectomy – live surgery</p>
            <p className="precongress__weight">
              <br />
              Excellence in Advanced laparoscopic surgery
            </p>
            <p>
              A Comprehensive Approach to Total Laparoscopic Hysterectomy and
              morcellation
            </p>
            <p>
              <br />
              Wednesday 31 May 2023; 8:15- 16:00.
            </p>
            <p className="precongress__weight">
              <br />
              Place
            </p>
            <p>
              OUH Odense University Hospital, Kløvervænget 23, entrance 55, 1st
              floor, DK 5000 Odense C, - look for signs.
            </p>
            <p>Deadline for registration 1 April 2023.</p>
            <p>
              <br />
              Download the programme here:&nbsp;
              <a
                className="PrecongressDownloadLink"
                href="/files/Course2.pdf"
                target="_blank"
                download={"Course 2-Myomectomy  live surgery-31-05-2022.pdf"}
              >
                Myomectomy – live surgery
              </a>
            </p>
            {/* <h3>Registration:</h3>
            <p>
              Registration on a first-come, first-served basis to:{" "}
              <a href="mailto:Annette.List@rsyd.dk?subject=NCGE-NCE2023 - Registration for Precongess Workshop">
                Annette.List@rsyd.dk
              </a>
            </p>
            <p>
              Please note in the subject field of the e-mail:{" "}
              <span className="precongress__weight">
                NCGE-NCE2023 - Registration for Precongess Workshop
              </span>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Precongress;
