import React from "react";
import "./accomArticle.css";

const AccomArticle = ({
  imgUrl,
  title,
  name,
  titleUnder,
  booking,
  overBooking,
  underBooking,
}) => {
  return (
    <div className="loadmore__accomodation-container_article">
      <div className="loadmore__accomodation-container_article-content">
        <h3>{name}</h3>
        <h5>{title}</h5>
        <div className="loadmore__accomodation-container_article-image">
          <img src={imgUrl} alt="blog" />
        </div>
        <p>{titleUnder}</p>
        <div className="booking__container">
          <p>{overBooking}</p>
          <div className="loadmore__accomodation-weight">
            <p>{booking}</p>
          </div>
          <p>{underBooking}</p>
        </div>
      </div>
    </div>
  );
};

export default AccomArticle;
