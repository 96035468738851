import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

export const Menu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const onMouseEnter = () => {
    setSubnav(true);
  };

  const onMouseLeave = () => {
    setSubnav(false);
  };

  return (
    <>
      <div
        className={"absoluteDrop"}
        onMouseEnter={item.subNav && onMouseEnter}
        onMouseLeave={item.subNav && onMouseLeave}
      >
        <NavLink
          exact="true"
          activeclassname="active"
          className="menuLine"
          to={item.path}
        >
          <div>
            {item.title}
            <div className="nav__sub">
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </div>
        </NavLink>
        <div className={subnav ? "dropdown dropdownA" : "dropdown"}>
          {subnav &&
            item.subNav.map((item, index) => {
              return (
                <Link className="dropdown-link" to={item.path} key={index}>
                  {item.title}
                </Link>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Menu;
