import React from "react";
import { Article } from "../../components";
import {
  blog01,
  blog02,
  blog03,
  blog06,
  blog09,
  blog12,
  blog13,
  blog14,
  blog15,
  blog16,
  blog17,
  blog18,
} from "./imports";
import "./scientific.css";

function Scientific() {
  return (
    <div className="committee__maxWidth">
      <div className="loadmore__committee section__padding">
        <div className="loadmore__committee-heading">
          <h1>Scientific Committee</h1>
        </div>
        <div className="loadmore__committee-container">
          <Article
            imgUrl={blog01}
            title="Congress President"
            name="Martin Rudnicki"
            titleUnder="Professor, Head of Minimal
Invasive Gynecological Surgery
Department of Gynecology and Obstetrics 
OUH Odense University Hospital"
            country={"Denmark"}
          />
          <Article
            imgUrl={blog02}
            title="Chairman Organizing Committee"
            name="Bjarne Rønde Kristensen"
            titleUnder="Chief Consultant, Associated Professor, MD
Department of Gynecology and Obstetrics 
OUH Odense University Hospital"
            country={"Denmark"}
          />
          <Article
            imgUrl={blog12}
            title="Committee Member"
            name="Axel Forman"
            titleUnder="Department of Gynecology and Obstetrics 
Aarhus University Hospital"
            country={"Denmark"}
          />
          <Article
            imgUrl={blog13}
            title="Committee Member"
            name="Mohamed Mabrouk"
            titleUnder="Gynecologist & Minimal Access Reproductive Surgeon, Cambridge Endometriosis & Endoscopic Unit, United Kingdom
Adjunct Professor
Department of Obstetrics and Gynaecology 
OUH Odense University Hospital"
            country={"Denmark"}
          />
          <Article
            imgUrl={blog14}
            title="Committee Member"
            name="Torur Dalsgaard"
            titleUnder="Senior Consultant, PhD
Endometriosis team 
Rigshospitalet"
            country={"Denmark"}
          />
          <Article
            imgUrl={blog06}
            title="Committee Member"
            name="Stine Andreasen"
            titleUnder="Head of Department Obstetrics and Gynecology 
Nordlandssykehuset"
            country={"Norway"}
          />
          <Article
            imgUrl={blog15}
            title="Committee Member"
            name="Klaus Oddenes"
            titleUnder="Department of Obstetrics & Gynecology
Haugesund Hospital "
            country={"Norway"}
          />
          <Article
            imgUrl={blog16}
            title="Committee Member"
            name="Päivi Härkki"
            titleUnder="Chief Physician, Adjunct Professor
Department of Gynecology and Obstetrics Helsinki University Central Hospital"
            country={"Finland"}
          />
          <Article
            imgUrl={blog09}
            title="Committee Member"
            name="Pia Suvitie"
            titleUnder="Deputy Chief
Department of Obstetrics and Gynecology
Turku University Hospital"
            country={"Finland"}
          />
          <Article
            imgUrl={blog17}
            title="Committee Member"
            name="Anna Kraen"
            titleUnder="Consultant and Specialist in laparoscopy
Malmö/Lund University Hospital
Lund/Skaane University Hospital"
            country={"Sweden"}
          />
          <Article
            imgUrl={blog18}
            title="Committee Member"
            name="Asgeir Thoroddsen"
            titleUnder="Consultant
Department of Gynecology and Obstetrics 
National University Hospital"
            country={"Iceland"}
          />
          <Article
            imgUrl={blog03}
            title="Committee Member"
            name="Sigurd Beier Sloth"
            titleUnder="MD, Ph.d.,
Department of Obstetrics and Gynaecology
Randers Regional Hospital"
            country={"Denmark"}
          />
        </div>
      </div>
      <div className="loadmore__committee-padding"></div>
    </div>
  );
}

export default Scientific;
