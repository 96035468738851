import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "./subMenu.css";

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  const location = useLocation();
  useEffect(() => {
    setSubnav(false);
  }, [location.pathname]);

  return (
    <>
      <div className="sidebarLink">
        <NavLink
          exact="true"
          activeclassname="activeSidebarLink"
          to={item.path}
        >
          <div className="sidebarLabel">{item.title}</div>
        </NavLink>
        <div className="openSubmenu" onClick={item.subNav && showSubnav}>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </div>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <Link className="dropdownLink" to={item.path} key={index}>
              {item.icon}
              <div className="sidebarLabel">{item.title}</div>
            </Link>
          );
        })}
    </>
  );
};

export default SubMenu;
