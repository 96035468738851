import React from "react";
import "./exhibitors.css";
import Gedeon from "../../assets/exhibitors_Gedeon.jpg";
import Applied from "../../assets/exhibitors_appliedmedical.png";
import Normedi from "../../assets/spons_normedi.png";
import Kebomed from "../../assets/exhibitors_kebomed.png";
import Storz from "../../assets/exhibitors_storz.jpg";
import Medtronic from "../../assets/exhibitors_Medtronic.png";
import BlueSurgical from "../../assets/exhibitors_BlueSurgical.png";
import KUNApgar from "../../assets/exhibitors_KUNApgar.jpg";
import Olympus from "../../assets/spons_olympus.png";
import Cast from "../../assets/spons_cast.jpg";
import Ferdinand from "../../assets/spons_ferdinand.jpg";
import Intuitive from "../../assets/exhibitors_intuitive.svg";
import CMR from "../../assets/exhibitors_cmr.jpg";
import Hemedic from "../../assets/exhibitors_hemedic.jpg";

const Exhibitors = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Exhibitors</h1>
        <div className="exhibitors_grid_container">
          <a
            href="https://www.gedeonrichter.se/dk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Gedeon} alt="Gedeon" />
          </a>
          <a
            href="https://www.appliedmedical.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Applied} alt="Applied" />
          </a>
          <a
            href="http://www.normedi.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Normedi} alt="Normedi" />
          </a>
          <a
            href="https://www.kebomed.dk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Kebomed} alt="Kebomed" />
          </a>
          <a
            href="https://www.karlstorz.com/dk/en/index.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Storz} alt="Storz" />
          </a>
          <a
            href="https://www.medtronic.com/dk-da/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Medtronic} alt="Medtronic" />
          </a>
          <a
            href="https://bluesurgical.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={BlueSurgical} alt="BlueSurgical" />
          </a>
          <a href="https://apgar.dk" target="_blank" rel="noopener noreferrer">
            <img src={KUNApgar} alt="KUNApgar" />
          </a>
          <a
            href="https://www.olympus.dk/medical/en/Products-and-Solutions/Products/Product/VISERA-ELITE-III.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Olympus} alt="Olympus" />
          </a>
          <a
            href="mailto:CAST.Europe@Castsurgical.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Cast} alt="Cast" />
          </a>
          <a
            href="http://www.ferdinandmedical.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Ferdinand} alt="Ferdinand" />
          </a>
          <a
            href="https://www.intuitive.com/en-gb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Intuitive} alt="Intuitive" />
          </a>
          <a
            href="http://www.cmrsurgical.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={CMR} alt="CMR_surgical" />
          </a>
          <a
            href="http://www.hemedic.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Hemedic} alt="hemedic" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Exhibitors;
