import React from "react";
import "./schedule.css";
import ImgSchedule from "../../assets/Schedule.png";

const Findyourway = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Exhibition schedule</h1>
        <h2 className="scheduleh2">Directory of Exhibitors</h2>
        <img src={ImgSchedule} alt="findyourway" />
        <h2>Hall A</h2>
        <table>
          <tr>
            <td>Booth no.</td>
            <td>Sponsor/Exhibitor</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Normedi Nordic</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Medtronic Danmark A/S</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Olympus Danmark A/S</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Intuitive Surgical Sárl</td>
          </tr>
          <tr>
            <td>5</td>
            <td>CAST Core Access Surgical Tecnologies Inc</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Ferdinand Medical ApS</td>
          </tr>
        </table>
        <h2>Hall B</h2>
        <table>
          <tr>
            <td>Booth no.</td>
            <td>Sponsor/Exhibitor</td>
          </tr>
          <tr>
            <td>7</td>
            <td>CMR Surgical Ltd</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Karl Storz Endoskopi Danmark A/S</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Apgar Danmark A/S</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Applied Medical Danmark ApS</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Blue Surgical ApS</td>
          </tr>
          <tr>
            <td>12</td>
            <td>KEBOMED A/S</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Gedeon Richter Nordics AB</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Hemedic Aps</td>
          </tr>
        </table>
      </div>
    </section>
  );
};

export default Findyourway;
