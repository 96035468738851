import React from "react";
import Form from "../../assets/abstractForm.png";
import "./abstract.css";

const Abstract = () => {
  return (
    <div className="abstract ">
      <div className="abstract__section section__padding">
        <h1>Abstract submission</h1>

        <h2>Dates and important information</h2>
        <p>Abstract submission deadline has been extended to: 1 May 2023</p>
        <p>Confirmation of abstract acceptance: 10 May 2023</p>
        <h4>
          Please submit abstracts via our website using the the abstract form
          displayed below
        </h4>
        <div className="abstract__section-text">
          <div className="abstract__section-text_wrap">
            <p>
              The scientific committee will evaluate all submitted abstracts.
              Accepted abstracts will be selected for either an Oral
              Communication/ an e-Poster
            </p>
          </div>
          <div className="abstract__section-text_wrap">
            <h4>
              The abstracts topics follow the themes of the individual
              scientific sessions: 
            </h4>
            <ul>
              <li>Endometriosis (all related topics)</li>
              <li>Surgical training and education</li>
              <li>Surgery in pregnancy</li>
              <li>Uterine malformations</li>
              <li>Myomas</li>
              <li>Urogynaecology</li>
              <li>Complications to gynaecological surgery</li>
              <li>Ovarian cysts</li>
            </ul>
            <p>
              <br />
              In addition, we invite abstracts regarding gynaecological
              endoscopy (hysteroscopy, laparoscopy, robotic-assisted surgery
              etc.)
            </p>
          </div>
        </div>
        <h2>Abstract submission:</h2>
        <p>Please use the abstract form displayed below</p>
        <div className="abstract__section-text">
          <div className="abstract__section-text_wrap">
            <h3>Oral Communication</h3>
            <p>
              Kindly note that selected abstracts for oral presentations will be
              presented during themed scientific sessions.
              <br />
              Abstracts selected for oral presentations should be presented in
              person at the congress (no pre-recorded presentations)
            </p>
          </div>
          <div className="abstract__section-text_wrap">
            <h3>E-posters</h3>
            <p>
              Abstracts selected for e-poster presentation and authors will be
              expected to prepare an e-poster according to instructions given.
              E-posters will be on display during the congress in a dedicated
              area.
              <br />
              Videos: If you like to submit a video, please submit your video to
              our e- mail including a link to access your video. Your video
              should not exceed 8 minutes.
            </p>
          </div>
        </div>
        <div className="abstract__section-text">
          <div className="abstract__section-text_form">
            <img src={Form} alt="AbstractForm" />
            <div className="abstract__buttonWrap-container">
              <div className="abstract__buttonWrap-form">
                <a
                  href="/files/AbstractForm.pdf"
                  target="_blank"
                  download={"Abstract Form"}
                >
                  Download Abstract Form PDF
                </a>
              </div>
              <div className="abstract__buttonWrap-form">
                <a href="mailto:Annette.List@rsyd.dk?subject=NCGE-NCE2023 - Abstract">
                  Submit Abstract
                </a>
              </div>
            </div>
            <div className="abstract__fade" />
          </div>
          <div className="abstract__section-text_wrap1">
            <h3>Guide to abstract submission:</h3>
            <p>
              Use the abstract template below
              <br />
              Please complete your abstract form following these instructions:
            </p>
            <ul>
              <li>
                Ensure your correct e-mail address is noted. Confirmation of
                acceptance will be sent to this address
              </li>
              <li>
                The preferred font is Arial 10, single spaced, left aligned.
                Maximum 250 words (excluding title and authors)
              </li>
              <li>Title of the abstract should be noted in BOLD</li>
              <li>
                Authors´ names should be listed in BOLD with “Surname first,
                then initials”, i.e. Jensen DM, Donald M.
              </li>
              <li>
                Abstract should be structured with: Background, Aims, Methods,
                Results, and Conclusions
              </li>
              <li>
                When you have finished the form, save it on your computer. Then
                press submit and attach the form. Please mark the e-mail in the
                subject field with NCGE-NCE2023 - Abstract; receipt will be
                acknowledged upon receipt.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abstract;
