import Ahlborg from "../assets/speakers/AhlborgLivKarlsson.jpg";
import Catena from "../assets/speakers/CatenaUrsula.jpg";
import Dalsgaard from "../assets/speakers/DalsgaardTorur.jpg";
import Einarsson from "../assets/speakers/EinarssonJon.jpg";
import Epstein from "../assets/speakers/EpsteinElisabeth.jpg";
import Hansen from "../assets/speakers/HansenMikkelSeyer.jpg";
import Hald from "../assets/speakers/HaldKirsten.jpg";
import Hanstede from "../assets/speakers/HanstedeMiriam.jpg";
import Härkki from "../assets/speakers/HarkkiPäivi.jpg";
import Hehenkamp from "../assets/speakers/HehenkampWouter.jpg";
import Ewa from "../assets/speakers/EwaHovi.jpg";
import Hudelist from "../assets/speakers/HudelistGernot.jpg";
import Jensen from "../assets/speakers/JensenJørgenBjerggaard.jpg";
import JensenR from "../assets/speakers/JensenRuneDall.jpg";
import Kallela from "../assets/speakers/KallelaHeidi.jpg";
import Kirk from "../assets/speakers/KirkUlrikBak.jpg";
import Klarskov from "../assets/speakers/KlarskovNiels.jpg";
import Mabrouk from "../assets/speakers/MabroukMohamed.jpg";
import Markauskas from "../assets/speakers/MarkauskasAlgirdas.jpg";
import Nyegaard from "../assets/speakers/NyegaardMette.jpg";
import Poulsen from "../assets/speakers/PoulsenBenteBækholm.jpg";
import Saridogan from "../assets/speakers/SaridoganNiels.jpg";
import Schijven from "../assets/speakers/SchijvenMarlies.jpg";
import Suvitie from "../assets/speakers/SuvitiePia.jpg";
import Uldbjerg from "../assets/speakers/UldbjergNiels.jpg";
import Valentin from "../assets/speakers/ValentinLil.jpg";
import Vink from "../assets/speakers/VinkMaarten.jpg";
import Persson from "../assets/speakers/PerssonJan.jpg";
import Dueholm from "../assets/speakers/Dueholm.jpg";
import Hartwell from "../assets/speakers/Hartwell.jpg";
import HjortIna from "../assets/speakers/HjortIna.jpg";
import Vercellini from "../assets/speakers/Vercellini.jpg";
import Alvirovic from "../assets/speakers/Alvirovic.jpg";

export const speakerData = [
  {
    id: "01",
    imageUrl: Ahlborg,
    name: "Ahlborg, Liv Karlsson,",
    title:
      "MD, PhD, Consultant in Department of Obstetrics and Gynecology Södertälje Region Hospital, S ",
    bio:
      "Main interests are minimally invasive surgery, endometriosis, educational aspects of surgery and patient safety.",
    pretext:
      "Objective: Attempting to define human factors and their relevance for patient safety in surgery.",
    abstract:
      "Background and findings: Nearly every 10th patient is affected by an adverse event (AE)," +
      "of which about 40% are preventable. Almost half of AEs in healthcare are related to surgical procedures." +
      "Technical skills are necessary but not sufficient alone to ensure patient safety in the operating room (OR)." +
      "The study of human factors (HF) addresses the entirety of socio-technical factors that affect process and safety within the OR. The intersection of numerous fields," +
      "including psychology and technology, has led to a considerable variation in the terminology, concept, and application of HF, resulting in a variable awareness of this topic." +
      "Non-Technical Skills for Surgeons (NOTSS) is one behavioral rating-tool developed for teaching and evaluating communication and team-work in the OR." +
      "The World Health Organization Surgical Safety Checklist is an example of a HF implementation that significantly reduced both morbidity and mortality." +
      "\n\nConclusions: Training of non-technical skills and considering HF appear to be essential for patient safety in surgery.",
  },
  {
    id: "02",
    imageUrl: Alvirovic,
    name: "Alvirovic, Jenny,",
    title: "Senior Consultant, N",
    bio:
      "Jenny Alvirovic is a senior consultant at the gynecological department at Oslo University Hospital. She is specialized in minimal invasive procedures, both hysteroskopic and laparoscopic techniques and robotic assisted surgery. Dr. Alvirovic is part of the multidisciplinary team at Oslo University hospital treating patients with deep endometriosis. She has a special interest in pelvic pain and Neuropelveology and is sertified by the ISON board. She is the Head of the Norwegian society of endoscopic surgery.",
    pretext:
      "Retropubic tension-free vaginal tapes (TVT) are safe to remove in toto with combined laparoscopic and vaginal approach in patiens with sling-related pain.",
    abstract:
      "Alvirovic, Jenny1, Dyrkorn, Ole2, Svenningsen, Rune1,2,3 \n\n" +
      "1Department of Gyneaecology, Oslo University Hospital\n" +
      "2Faculty of medicin, University of Oslo\n" +
      "3Norwegian Female Incontinence Registry (NFIR), Oslo University Hospital\n\n" +
      "Background: Synthetic slings used as treatment for stress urinary incontinence (SUI) may in some women cause chronic pain. The exact pathogenesis and incidence of this serious detrimental complication is to date largely unknown. However, a retropubic sling can be removed with a combined laparoscopic and vaginal technique many years after placement when the pain is believed to be sling-related.\n\n" +
      "Method: The surgical technique will be demonstrated by video. The patients underwent a physical follow-up within the first postoperative year. In addition, a follow-up by phone terview was conducted in august 2022. The effect of sling removal on pain intensity and continence status was evaluated by VAS-scale and the validated NFIR-questionnaire used by the national registry (NFIR). Main outcomes were pain relief, recurrence or worsening of stress urinary incontinence and surgical complications.\n\n" +
      "Results: Five patients with sling-related pain have had their TVT sling surgically removed in toto at Oslo University Hospital up until August 2022. The median age at time of surgery: 66 years (range: 53 — 78). Average time from primary surgery to sling removal: 4 years (range 0-10). Only two patients had had their primary TVT surgery at Oslo University Hospital. The ‘median time to follow-up after removal was 18 months (range 2 - 46). Four patients (80 %) had recurrence or worsening of their SUI after sling removal. All five women reported that they were satisfied (20 %) or very satisfied (80 %) with having had sling-removal surgery." +
      " ‘Three (60 %) patients were completely relieved of their sling-related pain (VAS = 0), while two were considerably better (50-70 % reduction in VAS score). Four patients (80 %) had stopped all use of pain medication. One patient was unexplainably, as a positive side effect, also cured of her anal urgency. The only registered complication was a unilateral ventral hernia at the site of sling removal 6 months after surgery.\n\n" +
      "Conclusion: Complete removal of a retropubic tension-free vaginal tape by combined laparoscopic and vaginal technique is a safe method with good short-term results on pain relief, but with a high risk of SUI recurrence.",
  },
  {
    id: "03",
    imageUrl: Catena,
    name: "Catena, Ursula,",
    title: "MD PhD, I ",
    bio:
      "Dr Ursula Catena obtained her Medical Degree in 2008," +
      "Certification in Obstetrics and Gynaecology in 2014 and PhD in Public Health in 2021.\n\n" +
      "Actually, she is one of the referents of the Digital Hysteroscopic Clinic “CLASS Hysteroscopy Center”" +
      "in the Division of Gynecological Oncology of the Fondazione Policlinico Universitario A. Gemelli - IRCCS in Rome.\n\n" +
      "Author of more than 20 articles published in international peer-review journals, co-author in 9 books and 1 educational DVD.\n\n" +
      "She has been personally invited as speakers in more than 70 national and international meetings and she has actively organized more than 50 national and international courses and scientific meetings.\n\n" +
      "She is Full Member of the Executive Board of the European Society of Gynecological Endoscopy (ESGE).\n\n" +
      "She has contributed to the development of the Gynaecological Endoscopic Surgical Education and Assessment (GESEA) program within the ESGE. Actually, she is Chief Mentor of the Training and Certification GESEA center of Fondazione Policlinico Universitario A. Gemelli - IRCCS in Rome.\n\n" +
      "She is chair of the ESGE Special Interest Group (SIG) in “Hysteroscopy” and member of the ESGE SIG (Special Interest Group) in “Innovation in Surgery”.",
    pretext: "Office hysteroscopy - how far can we get?",
    abstract:
      "In the last years, office hysteroscopic surgery has growth thanks to the miniaturization of the instruments and to the possibility to treat intrauterine pathologies in the same moment of the diagnosis (one-stop approach)." +
      "\n\nMoreover, the integration between Hysteroscopic Surgery and Ultrasounds has become fundamental. Gynaecologists performing endoscopic surgery should be able to perform also gynecological ultrasound to diagnose benign and malignant diseases and to guide some careful procedures." +
      "\n\nThe presentation aims to provide the necessary knowledge and some tips and tricks to treat simple and complex intrauterine pathologies with the help also of ultrasound.",
  },
  {
    id: "04",
    imageUrl: Dalsgaard,
    name: "Dalsgaard, Torur,",
    title: "Senior Consultant, MD, PhD, DK",
    bio:
      "Senior consultant in the endometriosis team at Rigshospitalet, Copenhagen, Denmark. Also responsible for the departments robotic surgery.\n\n" +
      "Board-certified by the Danish Society of Obstetrics and Gynecology as an expert in endometriosis.\n\n" +
      "Extensive experience with laparoscopic, and since 2010 also robotic, surgical treatment of complex gynecological cases, especially severe endometriosis.\n\n" +
      "Several publications on education, training, simulation and optimization of gynecological minimally invasive surgery.",
    abstract: "",
  },
  {
    id: "05",
    imageUrl: Dueholm,
    name: "Dueholm, Margit,",
    title: "Associated Professor l, DK",
    bio:
      "Associated Professor Aarhus University Hospital, Denmark.\n\n" +
      "Expirienced hysteroscopic and laparoscopic surgeon in benign uterine surgery.\n\n" +
      "More than 100 published papers.\n\n" +
      "Major research in gynecologic ultrasound and adenomyosis diagnosis and treatment.\n\n" +
      "Member of the MUSA group and FIGO group describing image standards in uterine abnormalities.",
    abstract:
      "Adenomyosis is a major cause of abnormal uterine bleeding, pain and infertility.\n\n" +
      "Diagnosis is performed by ultrasound and Magnetic Resonance Imaging, and imaging has during the last years been important in research for this disease. In this presentation we will present an update on present evidence on diagnosis, medical and surgical treatment of adenomyosis. ",
  },
  {
    id: "06",
    imageUrl: Einarsson,
    name: "Einarsson, Jon,",
    title: "Professor, MD PhD MPH, UK",
    bio:
      "Dr. Einarsson is the Founder of the Division of Minimally Invasive Gynecologic Surgery at Brigham and Women's Hospital and a Professor of Obstetrics," +
      "Gynecology and Reproductive Medicine at Harvard Medical School. He is also the past President of the AAGL. He maintains an active clinical research program," +
      "and has published approximately 200 peer-reviewed manuscripts, abstracts and book chapters.  He is also an innovator and holder of 16 medical device patent applications and granted patents." +
      "His referral practice is devoted to advanced laparoscopic surgery with approximately 350 complex cases performed annually. He also practices in Iceland in a private practice, where the main focus is on endometriosis surgery.",
    abstract:
      "Bowel resection and/or shaving\n" +
      "Endometriosis of the bowel effects approximately 5 to 12% of patients with endometriosis. The surgical management of bowel endometriosis has long been debated. There are three main options including shaving," +
      "disc excision or bowel resection. The techniques and indications for these various surgical treatment options is discussed along with their pros and cons along with patient selection criteria.\n\n" +
      "The big uterus\nUterine fibroids are the most common indication for hysterectomy and the procedure can be challenging in patients with very large fibroids especially fibroids that increase the uterine size over 1000 grams." +
      "In this lecture, we'll discuss techniques for safe and effective laparoscopic hysterectomy of the large uterus which include tips and tricks for handling of enlarged blood vessels as well as broad based specimens and appropriate pre-operative work up." +
      "We will also discuss contained tissue extraction techniques as well as the risk of potential occult malignancy and the consequences of uncontained morcellation.",
  },
  {
    id: "07",
    imageUrl: Epstein,
    name: "Epstein, Elisabeth,",
    title: "Professor, S",
    bio:
      "Professor in Obsterics and Gynecology at Karolinska Institutet, Stockholm, Sweden\n\n" +
      "Head of the unit for Gynecological Ultrasound Centre at Södersjukhuset, Karolinska Institutet, Stockholm, Sweden.\n\n" +
      "Active researcher focusing on ultrasound as a diagnostic tool in the management of gynecological tumors, with a broad international research network.\n\n" +
      "Supervising PhD student and post-Doc students\n\nTeaching medical students and gynecologists in training.\n\nCourse director of several ultrasound courses, both national and international.\n\nInvited speaker on a regular basis to International Conferences and Courses held by ISUOG and other international societies.",
    abstract:
      "Recent advances in artificial intelligence (AI), using deep neural networks (DNN), have shown promising results in discriminating between benign and malignant tumours with performance on par with expert radiologists in other domains. However, until recently, there has been no data on the use of DNN models for assessing ovarian tumours. Our previous retrospective internal study, including images from 750 women, was the first to indicate that DNN models can differentiate benign and malignant tumours, with a diagnostic accuracy similar to that of an expert examiner. Our own [unpublished] multi-centre validation study (20 centres), including over 17 thousand images from 3652 cases. We demonstrated that our DNN generalizes well across centers and that performance is significantly better than both expert and non-expert examiners (p<0.0001). Introducing AI-driven triage can substantially improve diagnostic accuracy at a very low cost of human resources.",
  },
  {
    id: "08",
    imageUrl: Hansen,
    name: "Hansen, Mikkel Seyer,",
    title:
      "consultant, leader of the endometriosis team, PhD and assistant prof., DK",
    bio:
      "Consultant, aas. prof, PhD and leader of the endometriosis team Aarhus.",
    abstract:
      "A presentation of the literature and own data regarding recurrence of endometriosis.",
  },
  {
    id: "09",
    imageUrl: Hald,
    name: "Hald, Kirsten,",
    title: "Senior Consultant, MD, PhD, N",
    bio:
      "Specialist in Gynecology and Obstetrics in Norway and Denmark after training at Sørlandet sykehus Kristiansand, Norway and Århus University Hospital, Denmark." +
      "Senior consultant gynecologist at Oslo University Hospital from 1998. PhD 2010 on uterine sparing myoma treatment. Head of Department of Gynecology since 2020." +
      "Clinical and research interests are gynecological minimal invasive surgery, uterine myomas, congenital malformations and disorders of sexual development.",
    pretext: "Uterine malformations",
    abstract:
      "The prevalence of uterine malformations in the unselected female population is about 5.5%. Longitudinal defects are most common with a prevalence of around 3%. These defects include uterine septum, bicorporal and unicorn uteri, and variants with narrow cavity. The longitudinal defects are most often asymptomatic. The impact of septate uterus on fertility and obstetrical outcome is uncertain, and there are controversies about the benefit of surgical correction of uterine septa, as well as T- and Y shaped uteri. Transverse defects are often obstructive and usually cause severe pain. Surgical conservative treatment can be difficult and few gynecologists gain a lot of experience since the anomalies are rare with a prevalence of about 0.05-0.001 %. These patients carry risk of hysterectomy, adhesions and endometriosis that may result in both infertility and chronical pain. Some variants of transverse defects may present with pain and hematometra in menstruating women. There may be duplication of uterus with occlusion on only one side, a more or less developed uterine cavity presenting as a uterine horn, or accessory cavitated uterine mass. Uterus agenesis results in absolute infertility. Most of these patients have uterine remnants; occasionally spots of endometrium can be seen. Some develop pain that can be relieved by extirpation of the uterine remnants. Endometriosis is a common finding in women with uterine anomalies, also in patients with longitudinal defects and uterus agenesis and must be considered if the patient complains of pain.",
  },
  {
    id: "10",
    imageUrl: Hanstede,
    name: "Hanstede, Miriam,",
    title: "Consultant, Head of the Asherman Expertise Center, NL",
    bio:
      "My passion on hysteroscopy has started as early as my first years of OB/GYN training. I was trained by world leaders on hysteroscopy." +
      "I had the privilege to be exposed to very complex intra uterine surgery in very high volumes." +
      "I mastered the techniques during my residency training and fellowship. As a consultant, I started a special clinic for Asherman’s patients where we offer 360-degree care of our patients.",
    abstract:
      "Septate Uterus and the impact on fertility\n\n" +
      "Congenital uterine anomalies are estimated to affect 8.5–12% in patients with infertility and recurrent pregnancy loss. The uterine septum is the most common form of congenital uterine anomalies. Whether the uterine septum increases the risk of reproductive failure is still uncertain. Several observational studies reporte an association between the uterine septum and obstetrical complications including recurrent spontaneous abortions, preterm delivery, intrauterine growth restriction and placental abruption. According to recent studies, the uterine septum may impair the embryo implantation and development through both molecular and mechanical mechanisms.\n\n" +
      "Hysteroscopic septum resection may be associated with an increased live birth rate and reduced preterm birth rate and miscarriage rate. However the first randomized controlled trial on hysteroscopic septum resection in women with reproductive disorders found no improvements in reproductive outcomes from the intervention, thereby questioning any rationale supporting surgical management. The debate on the impact of the uterine septum and its surgical correction on reproductive outcomes is still open.\n\n" +
      "Asherman syndrome, how to prevent and treat\n\n" +
      "Recognition that organic intrauterine adhesions can lead to secondary amenorrhea has been demonstrated since the end of the 19th century, although not until 1948, when Joseph Asherman described the eponymous condition in 29 patients, did the syndrome became popularized and treatment described. Asherman Syndrome (AS) is an acquired condition with hysteroscopically confirmed intra uterine adhesions (IUA) with the presence of clinical symptoms such as hypomenorrhea, amenorrhea," +
      " subfertility, or abnormal implantation of the placenta. Approximately 15-20% of clinically confirmed pregnancies result in miscarriage and are commonly managed surgically by suction dilation and curettage (D&C). Since IUA are thought to develop following the destruction of the basal layer of the endometrium, the finding of myometrium histologically amongst 44% of products of conception in 44% and 35% of termination and miscarriage specimens respectively suggests that damage is a common problem with this mode of intervention. In a meta-analysis, IUA were detected in 183 women after repeated curettage, resulting in a pooled prevalence of IUA of 19.1%\n\n" +
      "Some 30 % of women undergoing delayed surgical intervention in the late postpartum period have severe IUA. Generally, post-partum surgical interventions lead to higher grades of adhesion formation, and it is more difficult to restore the normal anatomy and to endometrial function. Fertility is substantially altered in this group with live birth reported in only 33.3% of women having a postpartum procedure for severe IUA.\n\n" +
      "Treatment seems mandatory in case of subfertility, pain or when the menstrual symptoms are unacceptable to the patient.  Adhesiolysis is performed, aiming to reshape the cavity to its normal size and shape and visualize the tubal ostia, or at least one of them. Hopefully restoring normal endometrial repair and regeneration, followed by prevention of re-adhesion formation and enhanced reproductive function. ",
  },
  {
    id: "11",
    imageUrl: Hartwell,
    name: "Hartwell, Dorthe,",
    title: "Senior Consultant, DK",
    bio:
      "Senior Consultant, Head of the Endometriosis Team, Department of Women's Diseases, the Juliane Marie Center, Rigshospitalet. ",
    abstract:
      "Endometriosis is a chronic gynecological disorder characterized by the presence of endometrial-like tissue outside of the uterus. Surgical management of endometriosis varies depending on the stage and location of the disease. The principles of surgery for endometriosis are to use minimally invasive endoscopic techniques and include complete excision of all visible endometriotic lesions, preservation of fertility, prevention of disease recurrence and minimizing surgical morbidity.\n\n" +
      "For early-stage endometriosis, conservative surgical management is recommended, which involves excision of endometriotic lesions with minimal tissue disruption. The aim is to preserve ovarian function and fertility, while minimizing the risk of disease recurrence. In advanced-stage endometriosis, extensive surgery may be necessary, which includes removal of deep infiltrating endometriosis, adhesiolysis, and restoration of normal pelvic anatomy. The goal is to improve symptom control, quality of life, and fertility outcomes.\n\n" +
      "The location of endometriosis also plays a crucial role in surgical management. For example, endometriosis involving the bowel may require bowel resection and anastomosis, while endometriosis involving the ureters may require ureterolysis or re-implantation. In addition, endometriosis involving the diaphragm may require thoracic surgery.\n\n" +
      "Overall, the most important principles in endometriosis surgery include careful preoperative assessment, individualized surgical planning based on the stage and location of the disease, meticulous surgical technique, and postoperative follow-up to monitor for disease recurrence. A multidisciplinary approach involving gynecologists, colorectal surgeons, urologists, and thoracic surgeons may be necessary in complex cases.",
  },
  {
    id: "12",
    imageUrl: Härkki,
    name: "Härkki, Päivi,",
    title: "MD PhD, Adjunct Professor, F",
    bio:
      "Dep Obst/Gyn Helsinki University Hospital, Finland.\n\n" +
      "Interested in benign gynecological surgery, especially severe endometriosis and in charge of the referral endometriosis center in Helsinki." +
      "Research projects on endometriosis, fibroids and training.",
    pretext: "URINARY TRACT ENDOMETRIOSIS",
    abstract:
      "Urinary tract endometriosis (UTE) affects 1-2% of women with endometriosis and up to 20% of women with deep endometriosis. Bladder endometriosis (BE) is the most common (85%), followed by that of ureter (9%), kidney (4%) and urethra (2%).\n\n" +
      "Half of the UTE patients present at least one urinary symptom: 21% dysuria, 16% voiding dysfunction, 6% polyuria, 6% hematuria, 4% cystitis, 2% urgency or 1% incontinence." +
      "Ureteral endometriosis (UE) is more often asymptomatic (60%) than other UTE locations (43%). BE is easily diagnosed with ultrasonography or MRI." +
      "The risk of UE increases if the rectovaginal lesion is larger than 3 cm and asymtomatic UE might lead to silent loss of renal function." +
      "UE is diagnosed with ultrasonography, urography or MRI and renal nephro/scintigraphy is recommended in case of severe hydronephrosis.\n\n" +
      "The treatment of choice for UTE not responding to hormonal treatment is surgery. In contrast, surgery is the first line treatment in UE causing hydronefrosis." +
      "Bladder resection for BE can be performed in most cases laparoscopically or robotically-assisted without the help of urologist. UE may present as intrinsic (25%) or extrinsic (75%)type." +
      "Intrinsic UE infiltrates the ureteral muscular layer and extrinsic UE infiltrates only adventitia or causes compression around the ureter." +
      "Surgical treatment options of UE are ureterolysis, ureter resection and end-to-end anastomosis, ureteroneocystostomy or nephrectomy in collaboration with urologist. Videos of different UTE surgeries are presented.",
  },
  {
    id: "13",
    imageUrl: Hehenkamp,
    name: "Hehenkamp, Wouter,",
    title:
      "Amsterdam University Medical Center, Department of Obstetrics and Gynaecology, NL",
    bio:
      "Wouter Hehenkamp has been a gynaecologist since 2011 and has been working ever since in the Amsterdam University Medical Center, The Netherlands. He is specialized in uterine abnormalities, focusing on innovative surgery and minimally invasive alternatives for surgery, as well as imaging." +
      "He has ample research experience within the field of benign gynaecology, mainly focused on uterine fibroids, uterine niches and adenomyosis. He has been a principle investigator in this field since 2015." +
      "He gained funding through several grants as primary applicant and supervised 16 PhD students (10 ongoing, 6 finished). He published more than 90 articles in peer-reviewed journals. Since 2019, he has a special interest in sustainability." +
      "He started the national gynaecologists green team (Gynae Goes Green) and has built a national network of green gynaecologists (1 ambassador in every hospital in the Netherlands). Also he is the founder of a national green think-tank." +
      "He has written columns and articles on sustainable healthcare and collaborates among others with TU Delft, LUMC and Radboud University, ‘National institute for public health and the environment (RIVM, the Netherlands) and the ‘Potsdam institute for climate impact research, Germany’." +
      "Two of his PhD students are focusing completely on sustainability within (and outside of) gynaecology and are fulltime appointed through grants in sustainability research.",
    abstract:
      "Minimally invasive treatments for fibroids\n" +
      "Many endoscopic surgeons that work within the field of gynaecology perform fibroid surgery in their clinical lives. Fibroids are diverse in size, number, location and consistency, making every case substantially different and thereby interesting but challenging." +
      "Exactly this diversity makes research on fibroid treatments difficult to interpret: what population has been investigated and are the outcomes applicable to my patient? Many alternatives have been described: uterine artery embolization, High Intensity Focused Ultrasound, Radiofrequency-ablation, but also medical treatments have emerged: ulipristal and GnRH antagonists." +
      "Good comparisons are rare and present difficulty in determining which treatment is superior and which patient is best helped with what treatment modality. It is important to bear in mind in what population the specific treatment has been investigated in order to be able to counsel a patient." +
      "Also, predictive variables for treatment success have not been very well established. This presentation aims at providing some clarity concerning patient selection and prediction on treatment outcome for fibroid surgery and its alternatives.\n\n" +
      "Sustainability in gynaecology\n" +
      "Climate change is probably the biggest threat to human health of our times and should be top-priority within the next decade in order to prevent catastrophes from happening. Even though no one is ‘against’ fighting climate change, making concrete choices for the better of the world seems difficult, both in peoples personal lives and at work." +
      "For healthcare things get even more complicated, because reducing possibilities to apply healthcare is often linked to a reduction in patient safety. Moreover, we lack knowledge on hotspots of carbon footprint for care pathways and processes within the hospital." +
      "For the operating room, in the last decades we have shifted from reusable products to disposable products, while a growing body of evidence reliably indicates that reusable is more sustainable and –in most cases-  less costly." +
      "Moving towards a net-zero carbon healthcare with optimal circularity has to be preceded by prioritizing this important theme and the willingness to make radical choices. All in favor of our patients, our children and future generations." +
      "This presentation provides an overview on where we stand in sustainability research and knowledge within healthcare and makes suggestions on the direction where we should be heading.",
  },
  {
    id: "14",
    imageUrl: HjortIna,
    name: "Hjort, Ina Marie Dueholm,",
    title: "Medical doctor, PhD student, DK",
    bio:
      "Medical doctor 2018, Aarhus University. PhD student, Aarhus University (Project: Improved diagnosis of ovarian cancer). ",
    abstract:
      "Pattern recognition is a key competence for correct handling of adnexal masses. A systematic approach at ultrasonography might support clinical decision making.\n\n" +
      "We implemented systematic description of adnexal masses by IOTA terminology in the Central Denmark Region.\n\n" +
      "This presentation will focus on interesting cases observed in the project.\n\n" +
      "Test if you can correctly identify malignant and benign adnexal masses based on videoclips.",
  },
  {
    id: "15",
    imageUrl: Ewa,
    name: "Hovi, Ewa",
    title: "MD, PhD, Specialist in OB & GYN, F",
    bio:
      "Senior consultant and Educational and clinical supervisor in Helsinki University Hospital and University of Helsinki.\n" +
      "Dissertation: Training laparoscopic skills – Changes in gynecological surgery 2020." +
      "Special interest: Postgraduate education, operative training, training the trainers\n\nE-mail: ewa.hovi@hus.fi",
    abstract:
      "Simulators have been available since the 80s, as they were introduced alongside with the operative laparoscopic surgery. Studies on simulators use in learning surgical skills have been published increasingly since the year 2000." +
      "Many studies have proven that it is possible to train and learn surgical skills, including laparoscopic skills, in different kind of training stations and laparoscopic simulators." +
      "In addition to that, it has been proven that these skills are transferred to the operation room (OR). By learning these skills before entering to the OR, during live surgery the trainee is allowable to concentrate on this certain case, on the operation itself and on non-technical skills.\n\n" +
      "Nowadays this training equipment has been obtained to most of the training hospitals, at least in Finland. However, just having the training tools does not guarantee that the training is successful. Lack of time is the most common factor prohibiting simulator training." +
      "The training tools should be easily available as training for a shorter time but often is more beneficial than training for a longer time but only once a year. Even better is if there is working time scheduled for training with simulators." +
      "A supervisor is needed to familiarize the trainees to the equipment and rehearsals, and looking after the tools. According to the studies published, supervised training sessions enhance learning, and to enable this, scheduled time is required for a supervisor, as well.\n\n" +
      "Clear objectives with achievable required skill levels in different drills, helps the trainee to realize his/her need to train. Setting up desirable skill levels in rehearsals makes the training program proficiency based, instead of less recommended one based on time or on number of repetitions." +
      "Motivation to train is either intrinsic, trainee’s own desire to gain better skills, or extrinsic, for example a certain skill test for getting a permission to work in the OR, or both.\n\n" +
      "Simulator training requires resources in form of training equipment and working time for both the trainees and the supervisors. However, this input is beneficial not only of educational point of view, but it also improves patient safety and optimizes the use of the operating room.",
  },
  {
    id: "16",
    imageUrl: Hudelist,
    name: "Hudelist, Gernot,",
    title: "Prof. PD. Dr., A",
    bio:
      "Consultant Gynaecologist, Full Professor of Obstetrics and Gynaecology at University of Vienna, Minimal invasive Surgeon with focus on surgical treatment of deep endometriosis and fertility disorders.\n\n" +
      "- over 130 publications in peer-reviewed journals\n" +
      "- Board member of the EEL (European Endometriosis League), SEF (Stiftung Endometrioseforschung) and OEGEO (Austrian Society of Endocrine Related Oncology)and Hungarian Society of Gynaecological Endoscopy\n" +
      "- Member of IDEA and #ENZIAN study groups on imaging and classification of deep endometriosis ",
    abstract:
      "Surgery for DE – long term outcomes, complications and sequelae\n\n" +
      "Long-term outcomes in terms of pain, quality of life (QoL), and gastrointestinal symptoms in women following colorectal surgery for deep endometriosis are sparsely evaluated to date." +
      "Long-term pain symptoms, Subjective overall QoL as rated using a score, pain symptoms and gastrointestinal outcomes reflected by lower anterior resection syndrome (LARS)are usual parameters reflecting patient wellbeing and thereby surgical outcomes." +
      "The presentation will provide the audience with a overview of the current literature, pros and cons of certain parametzers such as LARS and will question the validity of these outcome measures." +
      "Major complications of colorectal surgery, associated surgical techniques and their possible correlation with postoperative complications and sequelae will be issued in this presentation.",
  },
  {
    id: "17",
    imageUrl: Jensen,
    name: "Jensen, Jørgen Bjerggaard,",
    title: "Professor, DK",
    bio:
      "Jørgen Bjerggaard Jensen is clinical professor and chair within urology at Aarhus University Hospital with specialization in advanced minimally invasive pelvic surgery and reconstruction of the urinary tract.",
    abstract:
      "Most iatrogenic bladder and ureteric injuries occur during minimally invasive gynaecological surgery. What is the optimal approach once the lesion is there - and even more important: How can we prevent them? This is the subject of a tour de force through the urological system, focusing on what NOT do to.",
  },
  {
    id: "18",
    imageUrl: JensenR,
    name: "Jensen, Rune Dall,",
    title: "PhD, Associate Professor, DK ",
    bio:
      "With a big emphasis on development of expertise, Rune’s research area centers on surgical skill acquisition and performance in the operation room. His area of research expands development of technical skills and includes ‘non-technical skills’ and team performance.\n\n" +
      "The holistic approach to performance derives from a personal interest in philosophical perspectives and psychological influences on performance. Rune have actively adapted these thoughts to the elite sport sphere where he has worked since 2008.\n\n" +
      "This research on surgical expertise and talent development in surgery includes the selection of medical students, trainees in difficulty, and development of professional identity. Collectively, these elements are aimed at the transition from undergraduate to postgraduate, hence investigating the best possible transition and performance of trainees.",
    abstract:
      "The skills needed in the operating room (OR) include both technical skills as motor control and non-technical skills as communication. Literature point to that these skills can be seen as intertwined. Most research, however, investigates the skills as separable." +
      "In this line of research, we set out to explore the relationship between technical and non-technical skills in surgery. First, a scoping review was conducted. Included articles suggest that integration of non-technical and technical skills enhances performance and skills acquisition." +
      "Second, we created an interprofessional team training intervention to investigate if a combined skills training course would enhance surgical performance. The intervention was based on surgical team performance on anesthetized animal models. Each team consisted of two surgical residents and two OR nurses, who together performed more than 12 procedures over the course of three days." +
      "Each procedure was recorded and the technical and non-technical skills was assessed by blinded raters using OSATS and NOTTS. Preliminary findings favors team training to enhance both non-technical and technical skills. ",
  },
  {
    id: "19",
    imageUrl: Kallela,
    name: "Kallela, Heidi,",
    title: "titel, F ",
    bio:
      "Dr Kallela is working as a senior anaesthesia consultant in Women’s Clinic in Helsinki University Hospital. Women’s Clinic is a large center for obstetrics and gynaecology with 9000 deliveries per year." +
      "Her doctoral thesis was on recovery after gynaecologic day case surgery.",
    pretext: "Anesthesiologic tips and tricks during pregnancy",
    abstract:
      "The need for nonobstetric surgery can arise at any point during pregnancy and urgent and emergency surgeries cannot be delayed until after delivery\n\n" +
      "The lecture will discuss the perioperative anesthetic management of patients who undergo nonobstetric surgery during pregnancy with special focus on gynaecological laparoscopic surgery. Timing of surgery, obstetric outcomes, and perioperative obstetric outcomes will be briefly discussed.\n\n" +
      "There is no compelling evidence that any specific anesthetic agent is teratogenic in humans or should be avoided (with the possible exception of sugammadex). However, exposure to all medications should be minimized and titrated to effect if possible to protect the developing fetus. When appropriate, regional anaestesia is recommended.\n\n" +
      "Anatomic and physiologic changes during pregnancy require adjustments of anesthetic management. Cardiovascular, pulmonary, haematological and gastrointestinal changes as well as changes in sensitivity to anesthetic medications all have implications in the perioperative setting and will be discussed.\n\n" +
      "The importance of preoperative assessment and planning is emphasized. Tricks to administer safe anaesthesia with tips to optimize uteroplacental perfusion during anaesthesia to maintain the well-being of the fetus will be discussed.\n\n" +
      "Last but not least, the importance of team work is highlighted. ",
  },
  {
    id: "20",
    imageUrl: Kirk,
    name: "Kirk, Ulrik Bak,",
    title: "FEMaLe Coordinator & Program Manager, PhD Fellow, DK",
    bio:
      "I have worked in the Danish healthcare Sector for 10+ years, ultimately gaining my experience and expertise in digital public health, facilitating public-private-practitioner-patient Partnerships, to improve research and innovation across primary care. Since 2021, " +
      "I function part-time as FEMaLe's Coordinator and Project Manager at the Department of Public Health at Aarhus University; the 'Finding Endometriosis using Machine Learning' (FEMaLe) project will convert multi-omics datasets into a personalised predictive model to improve intervention along the continuum of care for people with endometriosis. Also, I am a part-time PhD Fellow; the goal with my PhD project is to understand why, how, and when video consultations in general practice work under which circumstances.",
    pretext: "Finding Endometriosis using Machine Learning",
    abstract:
      "Healthcare tools for predicting and preventing diseases as well as personalising treatment and patient management offer great clinical benefits and cost reduction. The EU-funded Finding Endometriosis using Machine Learning (FEMaLe) Project is working on a machine-learning multi-omics platform that can analyse omics data sets and feed the information into a personalised predictive model. The main focus of the project is to improve intervention for individuals with endometriosis; a combination of tools, such as the mobile application 'Lucy App' and augmented reality surgery software, will be co-developed, facilitating improved disease management and the delivery of precision medicine. The talk will give an updated overview of the FEMaLe Project and present preliminary results after its first 2 years." +
      "\n\nRead much more here: https://findingendometriosis.eu/ ",
  },
  {
    id: "21",
    imageUrl: Klarskov,
    name: "Klarskov, Niels,",
    title: "Professor, Consultant, DK",
    bio:
      "Niels Klarskov is professor at the University of Copenhagen and Consultant at the Dept. of Obstetrics and Gynecology at Herlev University Hospital. " +
      "His expert area is urogynecology and Niels Klarskov is especially interested in urodynamics and surgical methods for treating pelvic organ prolapse. ",
    abstract:
      "The Manchester Procedure – the return of the oldest minimal invasive Pelvic organ prolapse procedure.\n\n" +
      "The Manchester Procedure was described for the first time in 1888 by Archibald Donald as a surgical treatment of uterus prolapse. " +
      "The uterus is suspended by the cardinal ligaments and a part of the cervix is removed; thus, the procedure can be considered as the oldest minimal invasive surgical treatment of uterine prolapse. " +
      "The Manchester Procedure became popular all over the world and case series including almost 1000 cases have been published from the USA. When hysterectomy became safe in the 1970s, this treatment gradually superseded the Manchester procedure due to wishes for bleeding- and birth control. " +
      "After decades with hysterectomy, it became obvious that prolapse recurrence was frequent after hysterectomy and new treatments with mesh support were developed. However, with new treatments new complications emerged. " +
      "Recent studies have shown that the Manchester Procedure has fewer recurrences and complications and are less expensive compared to other surgical treatments of uterus prolapse. Nowadays, we have effective bleeding disorder treatments and successful birth control, thus, it might be time to re-introduce this first minimal invasive treatment of uterus prolapse.\n\n" +
      "How to introduce new surgical techniques – lessons learned from the introduction of vaginal mesh.\n\n" +
      "Innovation in surgery is crucial and the patients benefit from new, less invasive, or safer treatments. However, new techniques or procedures are not always a step forward. " +
      "The medical history has taught us that; new procedures may carry new complications, or new procedures might simply have poorer outcome or less durability than the established one. " +
      "The vaginal mesh operation is a good example of an innovation, which was a step backward, as it led to severe life changing complications. The extend of the complications after vaginal mesh operations is still not fully known. " +
      "In 2009, the IDEAL recommendations were published in the Lancet. IDEAL recommendation is a guideline how to introduce new treatments from the first idea to standard clinical practice. By pursue the IDEAL recommendation, we can timely secure the true innervation and avoid “backward steps” to the benefit of our patients.",
  },
  {
    id: "22",
    imageUrl: Mabrouk,
    name: "Mabrouk, Mohamed,",
    title: "Professor, MD, PhD, UK",
    bio:
      "Mohamed Mabrouk is a Consultant Gynaecologist and Minimal Access Reproductive Surgeon, with special interests in endometriosis, advanced endoscopic surgery.\n\n " +
      "Currently he is:\n• Co-director of the Cambridge Endometriosis Centre, Cambridge University Hospitals, UK. \n• Consultant gynaecologist and endometriosis specialist at the Cleveland Clinic London (CCL) \n• Director of Cambridge Endometriosis and Endoscopic Surgery Unit (CEESU) Centre at the Spire Lea Hospital, Cambridge, UK" +
      "• Adjunct Professor of Gynaecology in the University of Southern Denmark, Odense, Demark. \n• Professor of Obstetrics and Gynaecology at Alexandria University, Egypt. \n• President of the Middle East Society of Gynaecologic Endoscopy (MESGE).\n" +
      "• Member of the Advisory Board of the European Endometriosis League (EEL)",
    abstract: "",
  },
  {
    id: "23",
    imageUrl: Markauskas,
    name: "Markauskas, Algirdas,",
    title: "Senior Consultant",
    bio:
      "Algirdas Markauskas is a Senior Consultant at the Department of Gynaecology & Obstetrics, OUH Odense University Hospital in Denmark, European Society of Gynaecological Oncology certified gynaecologic oncology surgeon with special expertise within complex open and robotic procedures. Main field of interest is surgical morbidity and performance. ",
    abstract:
      "Morbidity following robotic assisted surgery\nRate of major complications following minimally invasive surgery is generally low as compared to open surgery. However, conventional laparoscopic surgery may be challenging in complex surgical situations or patients with substantial co-morbidities, especially morbid obesity, hereby increasing the risk for conversion to open surgery.  Introduction of robotic-assisted minimally invasive surgery at the Department of Gynaecology & Obstetrics, Odense University Hospital resulted in a substantial increase in total number of minimally invasive procedures performed, as improved dexterity was helpful to overcome challenges during complex surgical situations." +
      "\n\nWe present our comprehensive intra- and postoperative morbidity data following robotic-assisted minimally invasive oncological surgery based on prospective registration of all robotic procedures performed at department of Gynaecology & Obstetrics, Odense University Hospital since 2015 until present." +
      "\n\nPelvic sidewall anatomy - state of the art\nKnowledge of the surgical anatomy is crucial for successful and safe performance at the operation theatre. The lecture will provide a comprehensive walk-through the pelvic sidewall structures, retroperitoneal spaces and principles for safe surgical dissection.",
  },
  {
    id: "24",
    imageUrl: Nyegaard,
    name: "Nyegaard, Mette,",
    title: "Professor in Personalised medicine, MSc, PhD, DK",
    bio:
      "Mette Nyegaard is a professor in personalized medicine at Aalborg University, Department of Health Science and Technology, where she leads an independent research group, focused on genetic variation and how it shapes human diversity. Mette Nyegaard has a PhD from University of Southern Denmark, and has been a post-doctoral fellow at Stanford Medical School and an associate professor for 10 years at Aarhus University before moving to Aalborg. MN has a special interest in the genetics of endometriosis. Her group is leading the Danish contribution to the world’s largest international study identifying risks genes for endometriosis, using a so-called genome-wide association study. The results have recently been accepted for publication in the highest-ranking genetics journal, Nature Genetics. The group is also leading work to identify genetic subtypes of endometriosis as part of the EU funded project Finding Endometriosis using machine Learning (FEMaLe). The overall goal of the research is to translate genetic findings into mechanistic understanding of endometriosis, which may led to early diagnosis and personalized treatment.",
    abstract:
      "NYEGAARD M\nnyegaard@hst.aau.dk\n\nFROM GENES TO MECHANISM IN ENDOMETRIOSIS\n\n" +
      "Endometriosis has a substantial genetic contribution that can be studied to uncover biological mechanisms underlying disease. The aim of the presentation is to give an overview of some of the modern molecular technologies that are currently being applied to endometriosis. The talk will describe the findings from a recent large international genetic study, which has identified 42 risk genes for endometriosis. The presentation will also cover the results of our work in the EU funded project Finding Endometriosis using Machine Learning (FEMaLe), where genetics are used to search for subtypes of endometriosis. Finally, results from our early work investigating deep infiltrating bowel lesions using a technology called spatial transcriptomics will be presented, with the aim of investigating what genes are turned on and off in the interface between infiltrating lesion and normal tissue, while retaining the intact tissue architecture. ",
  },
  {
    id: "25",
    imageUrl: Persson,
    name: "Persson, Jan,",
    title: "Professor, S",
    bio:
      "Professor Jan Persson, Medical faculty of Lund University, Sweden, has his main research and clinical interest in oncologic and advanced benign applications in minimally invasive surgery and robot assisted surgery, the latter performed since 2005 as one of the few first centres in Europe. He has authored 72 peer reviewed papers in addition to book chapters and invited reviews and have supervised seven pHd students. He has, since 2008 organized 61 international masterclasses in robot assisted advanced surgery." +
      "\n\nHis main research interest during later years has been development of a structured  anatomically based sentinel node algorithm for endometrial and cervical cancers and further studies in lymphatic anatomy of the pelvis. ",
    abstract:
      "Complications:\nA display of the Lund safe hysterectomy concept will be demonstrated as well as related result from the last 2000 operations. Potential complications specifically associated with the robotic approach will be discussed." +
      "\n\nRobotic surgery during pregnancy:\nA video display of rare applications will be shown in addition to an overview of clinical use. ",
  },
  {
    id: "26",
    imageUrl: Poulsen,
    name: "Poulsen, Bente Bækholm,",
    title: "Consultant, DK",
    bio:
      "I am a consultant in the Gynaecological department at Aarhus University Hospital.\n" +
      "My work and interest are benign diseases of the uterus especially fibroids and anomalies.",
    abstract:
      "Impact of myoma on the endometrium and future therapy\nMyomas affect many women’s quality of life and in some cases their desire to achieve pregnancy. In particular, submucosal and intramural myomas may have a negative impact on fertility.\n\n" +
      "Studies suggest that certain risk factors can influence the presence and possibly the growth of myomas. These factors, as well as the myomas itself, may also affect the endometrium, possibly disturbing the fine mechanism in the “window of receptivity” of the endometrium.\n\n" +
      "The focus of the talk will be an update of the current knowledge about these factors including the possible effects, both positive and negative, on the endometrium after surgical treatment of fibroids. In addition, the possibilities for reducing risk factors, treatment and follow up after treatment will be discussed. ",
  },
  {
    id: "27",
    imageUrl: Saridogan,
    name: "Saridogan, Ertan,",
    title: "Professor, Consultant, UK",
    bio:
      "Ertan Saridogan is a Professor of Gynaecological Surgery at University College London and a Consultant in Gynaecology, Reproductive Medicine and Minimal Access Surgery at University College London Hospitals.  He is a former President of the British Society for Gynaecological Endoscopy and is the current President-Elect of the European Society for Gynaecological Endoscopy. He is the current Editor of Facts, Views and Vision:" +
      "Journal of the European Society for Gynaecological Endoscopy. He is also a member of the ESHRE and ESGE/ESHRE/WES Endometriosis Guideline Development Groups. He has been one of the authors of the ESHRE Endometriosis Guideline in 2005, 2013 and 2021.His research interests include noninvasive diagnosis of endometriosis, clinical outcomes following endometriosis surgery, outpatient hysteroscopy, and the place of screening and risk reducing surgery in women with a history of familial cancer.",
    abstract:
      "The European Society of Human Reproduction and Embryology released its updated (third) version of Endometriosis Guideline in 2022. The new guideline is completely re-written based on a new literature search using well established methodology for evidence based guidelines. The literature search was carried out to find answers to 35 PICO and 7 narrative questions which were drawn by the Guideline Development Group and stakeholders. The final document resulted in 109 recommendations or good practice points and 30 research recommendations.\n\n" +
      "One of the most significant changes in the new guideline is that laparoscopy is no longer the diagnostic gold standard and that it should be reserved for patients with negative imaging results and/or when empirical treatment is ineffective or inappropriate. In addition, the new guideline includes new medical treatments such as gonadotrophin releasing hormone (GnRH) antagonists and etonogestrel releasing implant, and has separate sections on fertility preservation and endometriosis in adolescents who are quite often overlooked leading to long diagnostic delays. There are also extended sections on endometriosis in relation to menopause and cancer.\n\n" +
      "The guideline has changed some of the previous recommendations, whilst some have been modified or re-worded compared to the previous guideline.",
  },
  {
    id: "28",
    imageUrl: Schijven,
    name: "Schijven, Marlies,",
    title: "Professor, MD PhD MHSc, NL",
    bio:
      "Full Professor of Surgery Amsterdam University Medical Centers, location AMC; the Netherlands.\n\n" +
      "Chair on Simulation, Serious Gaming and Applied Mobile Healthcare\nProgram Leader e-Health of the Netherlands Federation of University Medical Centres" +
      "Marlies Schijven (born 1969) is a board qualified surgeon and master in health sciences, researching the field of Mobile Healthcare, Simulation and Serious Gaming and surgical innovations in the OR such as wearables and medical data recorder systems. Her clinical work focuses on surgery for GERD, achalasia and upper GI motility disorders. Marlies is appointed both as Principle Educator and Principle Investigator in the Amsterdam" +
      "UMC, for which she is appointed project owner on the e-health domain considering the electronic patient file. She was appointed as the first CMIO (chief medical information officer) to the Dutch Government, and is currently the (supervisory) board member of several institutions and governing organizations including Meander Medical Center and ZonMW; The Netherlands Organization for Health Research and Development" +
      "\n\nMarlies is internationally recognized as an expert in virtual and augmented reality medical simulation, serious gaming, validation studies and –processes, e-health, app development for healthcare and is actively researching the MedTech domain; guiding several PhD residents. She successfully acquired subsidiaries and grand prizes. She is member of the EAES Technology Committee and Editor of journals among which BMJ Innovations, the Lancet Digital and JMIR Serious Games. Marlies is the Chief Editor of the Sage Journal Simulation and Gaming.",
    abstract: "",
  },
  {
    id: "29",
    imageUrl: Suvitie,
    name: "Suvitie, Pia,",
    title: "MD, PhD, F ",
    bio:
      "MD, PhD, Specialist in Obstetrics and Gynecology.\n" +
      "Debuty Chief, Department of OBGYN, Turku University Hospital, Finland",
    abstract:
      "Non-obstetric surgery is performed in 0,75-2% of all pregnancies. Urgent or emergency surgery should be performed without any delay in all trimesters. Elective surgery should be postponed and done after puerperium. Laparoscopy is safe during pregnancy and should be done instead of laparotomy whenever possible. Laparoscopy can be done up to 34 weeks, and should be done by an experienced surgeon to minimize the complication rate. Second trimester is the best time for laparoscopic gynecological surgery. Open entry is recommended. Complex surgery during pregnancy should be planned by a multidisciplinary team, including an obstetrician, a surgeon or a gynecological surgeon, anesthesiologist as well as a neonatologist if needed. Tocolysis may be considered if the risk of preterm delivery is increased (intra-abdominal infection, manipulation of the uterus, preterm contractions prior to surgery). When in weeks h 18-20 or later, patient or the operating table should be tilted a minimum 15 degrees to the left to avoid supine syndrome. This positioning should be remembered also right after surgery. Fetal CTG-monitoring before and after surgery is recommended if H >22 and pre- and postoperative ultrasound is the minimum in earlier weeks, but not much data exists behind the recommendation.",
  },
  {
    id: "30",
    imageUrl: Uldbjerg,
    name: "Uldbjerg, Niels,",
    title: "Professor, DK",
    bio:
      "Professor, Department, Obstetrics and Gynecology, Aarhus University.\nAbout 465 papers in peer reviewed international journals.\nGoogle Scholar (16/6-2022): H-index 58; Citations 11,787 " +
      "\n\nResearch profile\n\n• Basic laboratory research at the research laboratory\n• Connective tissue in the uterine cervix and fetal membranes\n• Preterm delivery: Infection, cervical incompetence\n• Maternal-fetal medicine.\n• Clinical trials including multicenter RCTs\n• Strength of the uterine cervix\n• Placental function by functional MRI",
    pretext:
      "Abdominal surgery during pregnancy, implications for ongoing pregnancy",
    abstract:
      "Aim:\n• Prevalence of surgery during pregnancy (excluding cesarean sections, amniocentesis, & CVS).\n• Birth outcome after surgery." +
      "\n\nMethods:\n• Danish registers; 1996-2015; 1,687,176 pregnancies.\n\nResults;\n• Non-obstetric surgery during pregnancy: 1.6% (Appendectomy 0.4%, diagnostic laparoscopy 0.4%; gallbladder 0.14%).\n• Laparoscopy for appendectomy: 	4% (1996-1999; 12/285) 	-> 79% (2012-2015; 210/265). 2012-2015: 98% (1 trimester); 85% (2 trimester); 30% (3 trimester).\n" +
      "\n• Laparoscopy for ovarian cysts: 	58% (1996-1999; 19/33) 	-> 83% (2012-2015; 74/89).\n• Internal herniation:		0 (1996-1999) 		-> 49 (2012-2015).\n• Birth > 2 weeks after operation:	Small for gestational age increased from 2.7% to 3.4%.\n• Operation before 32+0 weeks:	Very preterm birth increased from 0.8% to 2.2%.\n• Operation before week 22+0:	Risk of miscarriage increased from 6.1% to 8.2%.\n1st week after operation 4.3%, 2nd week 0.5%, 3rd week 0.3%\n• Diagnostic laparoscopy, appendectomy and cholecystectomy within one year prior to conception:\nNo significant effect on Small for Gestational Age, preterm birth, and miscarriage." +
      "Discussion:\n• The increased risk of very preterm birth after operation during pregnancy:\nThe indication or the procedure?",
  },
  {
    id: "31",
    imageUrl: Valentin,
    name: "Valentin, Lil,",
    title: "Senior Professor in Obstetrics and Gynecology",
    bio:
      "Professor in Obstetrics and Gynecology, Lund University, Sweden 2002 -2015\n\n" +
      "Senior Professor in Obstetrics and Gynecology, Lund University, Sweden since 2016\n\n" +
      "Major research interest: Obstetric and Gynecologic Ultrasound, Gynecologic Doppler in particular.\n\n" +
      "More than 200 scientific publications.\n\n" +
      "Invited speaker at more than 150 scientific meetings since 2005\n\n" +
      "Teacher at more than 100 national and international courses in obstetrics and gynecology (mainly ultrasound courses)\n\n" +
      "Course organizer of more than 40 postgraduate courses in ultrasound\n\n" +
      "Editor of the journal Ultrasound in Obstetrics and Gynecology 2005 – 2011\n\n" +
      "Honorary officer of numerous scientific associations and committees over the years, for example of ISUOG board 2004 – 2007, of ISUOG standards committee 2007-2015, the safety committee (ECMUS) of the European Federation of Ultrasound Societies in Medicine and Biology (EFSUMB) 2002-2012, and chairperson of the educational committee of EFSUMB 2002-2006, secretary (1994 to 2000) and chairperson (2000 to 2003) of the Swedish Society for Ultrasound in Medicine.\n\n" +
      "One of the founders of the International Ovarian Tumor Analysis (IOTA) and International Endometrial Tumor Analyses (IETA) collaborations.\n\n" +
      "Fellow ad eundum of the Royal College of Obstetricians and Gynaecologists (UK)\n\n" +
      "Honorary Fellow of the American Institute of Ultrasound in Medicine (AIUM) 2022\n\n" +
      "Received the Ian Donald Gold Medal 2019.",
    pretext:
      "Scoring systems of ovarian cyst – what to use? (IOTA score and ADNEX model)",
    abstract:
      "Lil Valentin, Senior Professor, Lund University, Sweden\n\n" +
      "The aim of the International Ovarian Tumor Analysis (IOTA) group is to create methods that can help less experienced ultrasound examiners to discriminate between benign and malignant adnexal masses based on their ultrasound appearance. The IOTA group has created 7 methods that one can use to discriminate between benign and malignant adnexal masses: Logistic regression model 1 (LR1), Logistic regression model 2 (LR2), the Simple Rules, the Simple Rules Risk calculation model, the Simple descriptors, the ADNEX model and the IOTA 2-step strategy. The Simple Rules and the Simple Descriptors do not require access to a computer, the other methods do. All IOTA methods have been prospectively validated on thousands of patients, and all have been shown to have excellent ability to discriminate between benign and malignant adnexal lesions. For the IOTA methods to work, one must know and use the IOTA definitions of the variables used in the methods." +
      "\n\nThe IOTA steering committee currently recommends to use the IOTA 2-step strategy. This means: first apply the Benign descriptors, if these do not apply, then apply the ADNEX model." +
      "\n\nThere are four benign descriptors" +
      "\n\nBenign descriptor 1: unilocular cyst, ground glass echogenicity, largest diameter <10 cm, premenopausal woman (most likely diagnosis is endometrioma)" +
      "\n\nBenign descriptor 2: unilocular cyst, mixed echogenicity, shadowing, largest diameter <10 cm, premenopausal woman (most likely diagnosis is dermoid cyst)" +
      "\n\nBenign descriptor 3: unilocular cyst, smooth inner and outer walls, anechoic cyst fluid, largest diameter <10 cm, (most likely diagnosis is simple cyst or cystadenoma)" +
      "\n\nBenign descriptor 4: all other unilocular cysts with smooth inner and outer walls, largest diameter <10 cm, (most likely diagnosis is benign cyst)" +
      "\n\nIf a benign descriptor applies to a mass, the risk of malignancy is <1% (validation results). Benign descriptors are applicable to almost 40% of all adnexal masses (validation results)." +
      "\n\nThe ADNEX model is a mathematical model that calculates the likelihood that an adnexal mass is benign, borderline, stage I primary invasive ovarian malignancy, stage II-IV primary invasive ovarian malignancy, or a metastasis in the ovary from another primary malignancy. The model includes 3 clinical and 6 ultrasound variables. The ultrasound variables are simple and robust. The ADNEX model is available for free on the IOTA website (www.iotagroup.org) and as an app (20 euros). The app is also incorporated in some ultrasound machines, so that the malignancy risk can be obtained during scanning. Using the risk calculated by ADNEX, the woman can be placed in a risk group of malignancy (e.g, low, intermediate or high risk, or in one of the four ORADS risk groups that are used in the USA). By coupling a suggested management to each risk group, the risk calculated by ADNEX can be incorporated in local, regional, national or international guidelines on how to manage adnexal lesions." +
      "\n\nThe ADNEX model has now been validated on 15316 patients in 28 countries. Summarizing the results of these validations in a meta-analysis showed that the area under the receiver operating characteristic curve (AUC) for ADNEX was 0.93 (95%CI 0.92 to 0.94), reflecting excellent discriminative ability (an AUC of 1 means that the test is perfect with no false positive and no false negative results).",
  },
  {
    id: "32",
    imageUrl: Vercellini,
    name: "Vercellini, Paolo,",
    title: "Professor, I",
    bio:
      "Full Professor of Obstetrics and Gynecology, Department of Clinical Sciences and Community Health, Università degli Studi, Milano, Italy\n\n" +
      "Head, Gynecology Division, “Luigi Mangiagalli” Institute, Fondazione IRCCS Ca’ Granda, Ospedale Maggiore Policlinico, Milano, Italy\n\n" +
      "Prof. Vercellini is a gynecologist specializing in the management of endometriosis, adenomyosis, uterine fibroids, chronic pelvic pain, and menorrhagia. His research interests include investigation of some pathogenic aspects of these diseases, and assessment of medical and surgical treatments.\n\n" +
      "He was elected a member of the executive committee of the European Society of Human Reproduction and Embryology (ESHRE) for the term 1995-1999. He has been President of the World Endometriosis Society for the term 2011-2014.\n\n" +
      "From 2015 to 2019 he has served as associate editor for Human Reproduction Update and, since then, for Human Reproduction, and serves in the Editorial Boards of Acta Obstetricia et Gynecologica Scandinavica, Journal of Obstetrics and Gynaecology Canada, Journal of Endometriosis and Uterine Disorders, and Italian Journal of Gynaecology and Obstetrics.\n\n" +
      "PV receives royalties from Wolters Kluwer for chapters on endometriosis management in the clinical decision support resource UpToDate.",
    pretext: "MEDICAL TREATMENT VERSUS SURGERY",
    abstract:
      "Medical treatment of endometriosis aims to relieve pain symptoms by inducing lesion quiescence through the creation of an anti-inflammatory hormonal milieu characterized by anovulation, amenorrhea, and low serum estrogen levels. This can be achieved by using combined hormonal contraceptives containing a very low dose of estrogen (i.e., ethinyl estradiol 0.015 mg or estradiol 1-1.5 mg) or progestogen monotherapy (e.g., dienogest, 2 mg). If the above first-line agents are ineffective, intolerable, or contraindicated, second-line agents, such as GnRH agonists or antagonists, may be used in combination with add-back therapies.\n\n" +
      "Medical treatments control but do not cure endometriosis, and prolonged periods of therapy should be planned, as pain symptoms usually recur after drug discontinuation. Therefore, not only efficacy, but also long-term safety, tolerability, and cost of different agents should be carefully considered. By definition, the induction of amenorrhea relieves dysmenorrhea. The effect of medical treatment on deep dyspareunia is variable and less predictable.\n\n" +
      "Medical treatment is not an option in women seeking conception or in those with adnexal masses of doubtful origin, obstructive uropathy, and subocclusive bowel lesions. Surgery should also be performed promptly when endometriotic cysts show ultrasound changes or increase in size during suppressive medical therapy. The effect of surgery on endometriosis-associated infertility is small in the early stages of the disease and still undefined in the advanced stages. However, excision of lesions provides varying degrees of pain relief and thus improves health-related quality of life in women trying to conceive naturally. Surgery is indicated in women with severe dyspareunia who do not respond to medical therapy, as well as in those who prefer to avoid the use of hormonal medications.\n\n" +
      "Long-term postoperative medical therapy has been shown to significantly reduce the risk of recurrence of lesions and pain symptoms and should be considered the standard of care for all women who do not wish to conceive immediately.",
  },
  {
    id: "33",
    imageUrl: Vink,
    name: "Vink, Maarten,",
    title: "Consultantl, NL",
    bio:
      "Maarten Vink is a consultant in urogynaecology in Amersfoort, the Netherlands.\n\n " +
      "Dr. Vink studied Medicine at Leiden University and International Business Administration (IBA) at Erasmus University Rotterdam. After completing his studies, dr. Vink became resident in Obstetrics and Gynaecology at University Medical Center Groningen, the Netherlands. Subsequently, he completed a two-year sub-specialty training programme in Urogynaecology at Isala Clinics Zwolle and Amsterdam University Medical Center / Bergman Clinics in December 2022. Next to his clinical work, dr. Vink is PhD student at the department of Economics at the Vrije Universiteit Amsterdam. His research focuses on medical practice variation of gynaecological interventions. ",
    abstract:
      "Pelvic organ prolapse (POP) is a frequent condition affecting almost 50% of all women. Literature shows that the lifetime risk for women to undergo surgery for POP is estimated between 11%-19%. The apical compartment, the descent of the uterus, cervix or vaginal vault, is important in the repair of POP. This was already shown by de Delancey, indicating the importance of Level 1 support. To repair apical (vault) prolapse, many different surgical techniques are available, e.g., the vaginal hysterectomy, sacrospinous fixation or Manchester Forthergill procedure. Next to the vaginal techniques for prolapse repair, laparoscopic techniques have becoming increasingly important in POP surgery, especially for repeat surgery." +
      "\n\nAfter 1994, the year in which the first case series of the laparoscopic sacrocolpopexy was published, several new techniques, devices and materials have been introduced to perform laparoscopic prolapse repair of the apical compartment. In this presentation, we will discuss the pros and cons of different (vaginal and laparoscopic) methods apical (vault) prolapse and advances in laparoscopic and robot assisted prolapse surgery. ",
  },
];
