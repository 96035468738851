import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import First from "../../assets/homePage00.png";
import coffee from "../../assets/sponsor00.png";
import food from "../../assets/sponsor01.png";
import sponsorExhibition from "../../assets/sponsor02.png";
import Martin from "../../assets/blog/blog01.png";
import Bjarne from "../../assets/blog/blog02.png";
import "./sponsors.css";

const Sponsors = () => {
  return (
    <div className="sponsors">
      <div className="sponsors__section section__padding">
        <h1>Sponsorship / Exhibition</h1>
        <div className="loadmore__home-content_first">
          <div className="loadmore__home-content_first-text">
            <h3>Welcome</h3>

            <p>
              We are proud to invite you to become a sponsor of or exhibitor at
              the The 4th Nordic Congress on Gynaecological Endoscopy/ Minimal
              Invasive Gynaecological Surgery (NCGE) and the 6th Nordic Congress
              on Endometriosis (NCE), June 1 - 3, 2023 in Middelfart, Denmark.
              <br />
              <br />
              The NCGE-NCE2023 Congress has high visibility within the medical
              and research community and is organized this time by the OUH
              Odense University Hospital. The Congress provides an optimal
              opportunity to learn about the latest progress. It will attract
              doctors and specialists and researchers within this area. A number
              of highly regarded Nordic lectures will participate in this
              Congress.
              <br />
              <br /> Except this Congress will also invite and encourage
              participants from other countries around the Baltic and North
              seas. The Congress will be held at Hindsgavl in Middelfart with
              exhibition facilities.
              <br />
              <br /> Overleaf you will find some examples of what your
              sponsorship could be, but sponsorships can also be tailored to the
              special needs and style of your particular company or
              organisation.
              <br />
              <br /> We look forward to collaborating with you. On behalf of the
              Organizing Committee
            </p>
          </div>

          <div className="loadmore__home-content_first-text_image">
            <img src={First} alt="First" />
          </div>
        </div>
        <div className="loadmore__home-content_third ">
          <div className="loadmore__home-content_third-text">
            <img src={Martin} alt="Martin" />
            <div className="loadmore__home-content_third-text_container">
              <div className="loadmore__home-content_third-text_container-white">
                <h3>Martin Rudnicki</h3>
                <p>
                  Congress President
                  <br />
                  <br />
                  Professor
                  <br /> Head of Minimal Invasive Gynecological Surgery
                  <br />
                  <br /> Department of Gynecology and Obstetrics
                  <br /> OUH Odense Odense University Hospital
                </p>
              </div>
            </div>
          </div>
          <div className="loadmore__home-content_third-text">
            <img src={Bjarne} alt="Bjarne" />
            <div className="loadmore__home-content_third-text_container">
              <div className="loadmore__home-content_third-text_container-white">
                <h3>Bjarne Rønde Kristensen</h3>
                <p>
                  Chairman of the Organizing Committee
                  <br />
                  <br /> Chief Consulant, Associated Professor
                  <br />
                  Head of Studies, MD
                  <br />
                  <br />
                  Department of Gynecology and Obstetrics
                  <br /> OUH Odense Odense University Hospital
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2>
          Sponsor Levels and Benefits{" "}
          <p>
            NCGE-NCE2023 is approved by Ethical Medtech, link:{" "}
            <a
              className="ethicalMed"
              href="https://www.ethicalmedtech.eu/medtech-apps/cvs/view-event/EMT32723"
              target="_blank"
              rel="noopener noreferrer"
            >
              EthicalMedtech
            </a>
          </p>
        </h2>

        <p>
          Four levels of sponsorship are available as set out below.
          <br />
          The benefits indicated are examples and can be tailored to your needs
          and style.
        </p>
        <div className="sponsors__section_container">
          <div className="sponsors__section_container_benefits">
            <h5 className="platinum">Platinum Sponsors</h5>
            <div className="sponsors__section_container_text">
              <p>20 square meters exhibition area</p>
              <p>
                Symposium 30 minutes. In addition all costs in connection with
                the speaker.
              </p>
              <p>
                Company´s logo and naming as “PLATINUM SPONSOR” in all congress
                printing materials and on the congress website
              </p>
              <p>
                Company logo, short profile and link on the congress website
              </p>
              <p>Company logo and short profile in the congress newsletter</p>
              <p>Company logo in the main meeting hall</p>
              <p>
                2 advertisements in the Final program – inside back cover full
                page and inner full page
              </p>
              <p>4 full registrations for delegates</p>
              <p>4 exhibitor registrations</p>
              <p>
                First choice of exhibition location, on a first-come
                first-served basis.
              </p>
            </div>
          </div>
          <div className="sponsors__section_container_benefits">
            <h5 className="gold">Gold Sponsors</h5>
            <div className="sponsors__section_container_text">
              <p>15 square meters exhibition area</p>
              <p>
                Company´s logo and naming as “GOLD SPONSOR” in all congress
                printing materials and on the congress website
              </p>
              <p>Company logo and link on the congress website</p>
              <p>Company logo in the congress newsletter</p>
              <p>Company logo in the main meeting hall</p>
              <p>1 advertisement in the Final program – inner full page</p>
              <p>2 full registrations for delegates</p>
              <p>2 exhibitor registrations</p>
            </div>
          </div>
          <div className="sponsors__section_container_benefits">
            <h5 className="silver">Silver Sponsors</h5>
            <div className="sponsors__section_container_text">
              <p>12 square meters exhibition area</p>
              <p>Company logo and link on the congress website</p>
              <p>Company logo in the congress newsletter</p>
              <p>1 advertisement in the Final program - half page</p>
              <p>2 exhibitor registrations</p>
              <p>Company name and logo printed in the Final program</p>
            </div>
          </div>
          <div className="sponsors__section_container_benefits">
            <h5 className="bronze">Bronze Sponsors</h5>
            <div className="sponsors__section_container_text">
              <p>8 square meters exhibition area</p>
              <p>Company logo and link on the congress website</p>
              <p>Company logo in the congress newsletter</p>
              <p>2 exhibitor registration</p>
              <p>Company name and logo printed in the Final program</p>
            </div>
          </div>
        </div>
        <div className="sponsors__section_features">
          <div className="sponsors__section_features_text">
            <h2>Individual features available for sponsorship</h2>
            <p>
              All sponsored activities are subject to approval by the Organizing
              Committee.
            </p>
            <h5>Lanyards</h5>
            <p>Logos printed on the lanyards.</p>
          </div>
          <div className="sponsors__section_features_image">
            <img src={coffee} alt="coffee" />
          </div>
        </div>
        <div className="sponsors__section_features">
          <div className="sponsors__section_features_image">
            <img src={food} alt="food" />
          </div>
          <div className="sponsors__section_features_text">
            <h2>Sponsored Symposium</h2>
            <p>
              Available exclusively to exhibitors and sponsors.
              <br />
              The sponsored Symposia (SS) is an excellent way for companies to
              communicate their latest scientific, technical research, or
              product developments to the delegates.
              <br />
              <br />
              Limited session times will be available during the Congress. The
              SS will be promoted prior to the Congress/ on the website and in
              the Final Programme.
            </p>
            <p className="sponsors__italic">Available symposia</p>
            <ul>
              <li>
                1 June 2023:
                <ul>
                  <li>10:30 – 11:00</li>
                  <li>12:45 – 13.:30</li>
                </ul>
              </li>
              <li>
                2 June 2023:
                <ul>
                  <li>08:00 – 08:50</li>
                  <li>10:30 – 11:15</li>
                  <li>13:00 – 13:45</li>
                </ul>
              </li>
              <li>
                3 June 2023:
                <ul>
                  <li>08:45 – 09:30</li>
                </ul>
              </li>
            </ul>
            <p>
              <br />
              <br />
              A SS should fit into the standard time unit of a 30/25-minute
              session. The SS must be open to all Congress participants. All
              persons attending must be registered as full participants or as
              exhibitors.
              <br />
              <br />
              It is strictly forbidden to hold satellite symposia outside the
              Congress premises during the two days before the Congress or the
              two days immediately after the Congress. Any formal presentation
              occurring at hospitality events will be regarded as symposia, to
              which the above mentioned rules apply.
              <br />
              <br />
              In order to be considered by the Scientific Committee,
              applications to hold a SS including a preliminary topic should be
              approved by the Congress Secretariat.
            </p>
            <p className="sponsors__italic">Rules for Sponsored Symposia</p>
            <ol>
              <li>
                Symposia are scientific sessions sponsored by industry, which
                may be attended by registered Congress participants free of
                charge.
              </li>
              <li>
                Symposia are held between the scientific programme sessions.
              </li>
              <li>
                The quality of the Scientific Programme of symposia has to be
                approved by the Organizing Committee.
              </li>
              <li>
                The Final programme must be submitted three months before the
                Congress, i.e. by the deadline for the Final Programme.
              </li>
              <li>
                Alterations to the programme are only allowed if accepted by the
                Organizing Committee.
              </li>
              <li>
                The fee includes a lecture room, standard set-up used at the
                Congress, standard AV equipment and technical assistance. All
                other expenses (e.g. additional equipment, invited speakers,
                refreshments, etc.) are the responsibility of the sponsor.
              </li>
              <li>
                The NCGE-NCE2023 logo may be used on invitations to the
                symposia.
              </li>
            </ol>
          </div>
        </div>
        <div className="sponsors__exhibition">
          <h2>Exhibition</h2>
          <ul>
            <li>Exhibition space from 4 square meters</li>
            <li>1 exhibitor registration per 4 square meters</li>
            <li>Company logo on the congress website</li>
            <li>Company logo printed in the Final program</li>
          </ul>
        </div>
        <div className="secretary__contact">
          <h2>
            Congress Secretariat -<br /> Sponsorship
          </h2>
          <p>
            For questions or further information regarding Sponsorship or
            Exhibition, please contact:
          </p>
          <address>
            E-mail:{" "}
            <a href="mailto:Annette.List@rsyd.dk">Annette.List@rsyd.dk</a>
          </address>
        </div>
        <div className="sponsors__section_features">
          <div className="sponsors__section_features_text">
            <h2>Also see</h2>
            <p>
              For further information about the Scientific Programme, Organizing
              Committee, Venue and Precongress Workshop - see the following
              pages bellow
            </p>
            <div className="sponsors__buttonWrap-form">
              <NavLink to="/programme">Scientific Programme</NavLink>
            </div>
            <div className="sponsors__buttonWrap-form">
              <Link to="/committee#organizing">Organizing Committee</Link>
            </div>
            <div className="sponsors__buttonWrap-form">
              <NavLink to="/venue">Venue</NavLink>
            </div>
            <div className="sponsors__buttonWrap-form">
              <NavLink to="/precongress&workshop">Precongress Workshop</NavLink>
            </div>
          </div>
          <div className="sponsors__section_features_image">
            <img src={sponsorExhibition} alt="Sponsor&Exhibition" />
            <div className="abstract__buttonWrap-container">
              <div className="abstract__buttonWrap-form">
                <a
                  href="/files/Sponsorship_Brochure.docx"
                  target="_blank"
                  download={"Sponsorship_Brochure"}
                >
                  Download Sponsorship/Exhibition PDF
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
