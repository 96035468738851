import React from "react";
import "./article.css";

const Article = ({ imgUrl, title, name, titleUnder, country }) => {
  return (
    <div className="loadmore__committee-container_article">
      <div className="loadmore__committee-container_article-image">
        <img src={imgUrl} alt="blog" />
      </div>
      <div className="loadmore__committee-container_article-content">
        <h3>{name}</h3>
        <h5>{title}</h5>
        <p>{titleUnder}</p>
        <p>{country}</p>
      </div>
    </div>
  );
};

export default Article;
