import React from "react";
import "./findyourway.css";
import findyourwayIMG from "../../assets/findyourway.jpg";

const Findyourway = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Find your way</h1>
        <img src={findyourwayIMG} alt="findyourway" />
        <h2>A little help for the journey - travel guide</h2>
        <p>
          You can fly to Billund Airport or to Copenhagen Airport. Here is a
          link to the “REJSEPLANEN”:{"  "}
          <a
            href="https://www.rejseplanen.dk/webapp/?language=en_EN&#!P|TP!histId|0!histKey|H858609"
            target="_blank"
            rel="noopener noreferrer"
          >
            rejseplanen.dk
          </a>
          , where you can find information about the easiest journey from
          Billund or Copenhagen Airport to Middelfart Station. When you arrive
          in Middelfart, you must take a taxi to Hindsgavl.
        </p>
        <p>
          You can order a taxi by contacting Taxi Syd by phone{" "}
          <a href="tel:004564414055">+045 64 41 40 55</a>.
        </p>
      </div>
    </section>
  );
};

export default Findyourway;
