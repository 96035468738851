import React from "react";
import "./assembly.css";
import Agenda from "../../assets/assembly.jpg";
import Agenda2 from "../../assets/assembly2.jpg";

const Assembly = () => {
  return (
    <section className="assembly">
      <div className="assembly__container section__padding">
        <h1>NSGE-General Assembly</h1>
        <h2>Thursdag, 1 June 2023, 17:45 - 18:15</h2>
        <img className="assembly_img" src={Agenda} alt="General Assembly" />
      </div>
      <img className="assembly_img1" src={Agenda2} alt="General Assembly" />
    </section>
  );
};

export default Assembly;
