import React from "react";
import "./venue.css";

import forside4 from "../../assets/forside4.jpg";
import Hindsgavl2 from "../../assets/venue01.png";
import Hindsgavl3 from "../../assets/venue02.png";
import Hindsgavl4 from "../../assets/venue03.png";

const Venue = () => {
  return (
    <div className="venue">
      <div className="venue__section section__padding">
        <h1>Venue</h1>
        <div className="venue__section-container">
          <div className="venue__section-container_text">
            <h2>Hindsgavl</h2>
            <p>
              Close to Middelfart city and train station but in the middle of
              the most beautify Danish nature Hindsgavl is the perfect balance
              between well-equipped facilities, professional planning, and the
              beautiful Funen summer and nature for walk and talks, networking
              events etc. as
            </p>
            <ul>
              <li>
                Modern conference hotel, with top-of-the-line conference
                facilities.
              </li>
              <li>Meeting and conference rooms for up to 650 persons</li>
              <li>Technical assistance at any time</li>
              <li>Free WiFi throughout</li>
              <li>Around the clock reception services</li>
              <li>Lovely rooms</li>
              <li>
                A kitchen that primarily use local produce and when the seasons
                allow it fruits,
                <br /> vegetables, and herbs from our own kitchen garden
              </li>
            </ul>
          </div>
          <div className="imageWrap imageSize1">
            <img src={forside4} alt="Hindsgavl-Venue" />
          </div>
        </div>
        <div className="venue__section-container">
          <div className="imageWrap imageSize2">
            <img src={Hindsgavl2} alt="Hindsgavl-Venue2" />
          </div>
          <div className="venue__section-container_text">
            <h2>History</h2>
            <p>
              The history of Hindsgavl goes all the way back to the 13th
              Century. The current Hindsgavl was built in 1784. Hindsgavl has
              been under siege and housed royal people whose Former home was
              under siege.
              <br />
              <br />
              Hindsgavl with its surrounding buildings has been on Fire several
              times, most recent in 1977, which ended the housing of livestock
              at Hindsgavl. All that was burned down was restored and From 2009
              to 2011 the stables and the barn was Further restored to contain
              hotel rooms and conference Facilities.
              <br />
              <br />
              Today Hindsgavl is a hotel, conference center and restaurant with
              120 rooms, conference Facilities to host up to 650 participants in
              one room with 13 additional smaller conference rooms, and all of
              this with a big wonderful park located right in the middle of
              Denmark.
            </p>
          </div>
        </div>
        <div className="venue__section-container">
          <div className="venue__section-container_text">
            <h2>Hindsgavl</h2>
            <p>
              Unique comprehensive experience In addition to the unique,
              one-of-a-kind surroundings, you will experience a modern hotel and
              nuanced Danish cuisine.
            </p>
          </div>
          <div className="imageWrap imageSize2">
            <img src={Hindsgavl3} alt="Hindsgavl-Venue" />
          </div>
        </div>
        <div className="venue__section-container">
          <div className="imageWrap imageSize3">
            <img src={Hindsgavl4} alt="Hindsgavl-Venue2" />
          </div>
          <div className="venue__section-container_text">
            <h3>Sustainability</h3>
            <p>
              At Hindsgavl we want to be a part of the social, as well as
              environmental responsibility, which is part of operating a large
              hotel- and conference center.
              <br />
              <br />
              However, given the cultural heritage of Hindsgavl, it is not
              always possible to launch initiatives to promote corporate
              responsibility. We do what is possible within the restrictions
              associated with listed buildings.
              <br />
              <br />
              At Hindsgavl we are open minded to diversity in our staff.
              Diversity contributes to strengthening our profile and identity
              which we believe benefits our guests as well as our staff.
              <br />
              <br />
              We support the local society. We buy locally whenever possible and
              involve ourselves in various development projects. To minimize
              environmental issues, we have involved environmental initiatives
              as a natural part of our daily operations.
            </p>
            <h4>Waste management</h4>
            <p>
              Although we focus on avoiding food waste it is difficult to
              completely avoid waste, in a large kitchen like ours. All of our
              food waste is sent for incineration and turned into biomass and
              subsequently converted into electricity. By-products from the
              combustion process are used for fertilizer. Furthermore we focus
              on sorting cardboards and bottles for recycling.
            </p>
            <h4>Drinking water</h4>
            <p>
              We are aware that bottled water is very heavy on the environment
              and we are working towards being 100% free of bottled water by
              installing tap-water stations by/in the meetingrooms.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Venue;
