import React from "react";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Programme",
    path: "/programme",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Scientific programme",
        path: "/programme",
      },
      {
        title: "Final Programme-printet",
        path: "/final",
      },
      {
        title: "Pre-congress Workshop",
        path: "/precongress&workshop",
      },
      {
        title: "Speakers",
        path: "/speakers",
      },
      {
        title: "Social events",
        path: "/social&events",
      },
      {
        title: "Selected abstracts",
        path: "/posters",
      },
    ],
  },
  {
    title: "Venue",
    path: "/venue",
  },
  {
    title: "Abstracts",
    path: "/abstracts",
  },
  {
    title: "Registration",
    path: "/registration",
  },
  {
    title: "Exhibition and Sponsors",
    path: "/sponsors&exhibition",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Sponsors",
        path: "/spons",
      },
      {
        title: "Exhibitors",
        path: "/exhibitors",
      },
      {
        title: "Exhibition schedule",
        path: "/schedule",
      },
    ],
  },
  {
    title: "NSGE-General Assembly",
    path: "/assembly",
  },
  {
    title: "Accommodation",
    path: "/accomodation",
  },
  {
    title: "General Information",
    path: "/general",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Organizing Committee",
        path: "/organizing",
      },
      {
        title: "Scientific Committee",
        path: "/scientific",
      },
      {
        title: "Speaker information",
        path: "/speaker&info",
      },
      {
        title: "Find your way",
        path: "/findyourway",
      },
      /*{
        title: "Language",
        path: "/language",
      },
      {
        title: "Information from A to Z",
        path: "/info",
      },*/
    ],
  },
];
