import React from "react";
import "./goodtoknow.css";
import socialevent from "../../assets/socialevent.jpg";

const GoodToKnow = () => {
  return (
    <div className="social">
      <div className="social__section section__padding">
        <h1>Social Events</h1>
        <img src={socialevent} alt="SocialEvent" />
      </div>
    </div>
  );
};

export default GoodToKnow;
