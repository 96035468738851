import React from "react";
import { Routes, Route } from "react-router-dom";
import Helmet from "react-helmet";

import {
  Home2,
  Congress,
  Venue,
  GoodToKnow,
  Programme,
  Scientific,
  Precongress,
  Abstract,
  Registration,
  Sponsors,
  Accomodation,
  Spons,
  Exhibitors,
  Speakers,
  Findyourway,
  Schedule,
  Posters,
  General,
  Assembly,
  Final,
  Speakinfo,
  Organizing,
} from "./containers";
import { Layout } from "./components";
import "./App.css";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>NCGE & NCE</title>
        <link rel="canonical" href="https://ncge-nce2023.dk" />
        <meta
          name="NCGE and NCE"
          content="Nordic Congress on 
      Gynaecological Endoscopy and Nordic Conference on
      Endometriosis"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout className="navbar" />}>
          <Route index element={<Home2 />} />
          <Route path="general&information" element={<Congress />} />
          <Route path="venue" element={<Venue />} />
          <Route path="precongress&workshop" element={<Precongress />} />
          <Route path="social&events" element={<GoodToKnow />} />
          <Route path="programme" element={<Programme />} />
          <Route path="scientific" element={<Scientific />} />
          <Route path="organizing" element={<Organizing />} />
          <Route path="abstracts" element={<Abstract />} />
          <Route path="accomodation" element={<Accomodation />} />
          <Route path="registration" element={<Registration />} />
          <Route path="sponsors&exhibition" element={<Sponsors />} />
          <Route path="spons" element={<Spons />} />
          <Route path="exhibitors" element={<Exhibitors />} />
          <Route path="speakers" element={<Speakers />} />
          <Route path="findyourway" element={<Findyourway />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="posters" element={<Posters />} />
          <Route path="general" element={<General />} />
          <Route path="assembly" element={<Assembly />} />
          <Route path="final" element={<Final />} />
          <Route path="speaker&info" element={<Speakinfo />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
