import React from "react";
import "./congress.css";

import Helsinki from "../../assets/congress02.png";
import Norefjell from "../../assets/congress03.png";

const Congress = () => {
  return (
    <div className="loadmore__congress section__padding">
      <div className="loadmore__congress-container">
        <div className="loadmore__congress-container__text">
          <h3>Congress</h3>
          <div className="scaling">
            <p>
              Vestibulum sagittis neque vitae risus malesuada, vel facilisis
              diam rhoncus. Nullam bibendum ante ac nisi facilisis, fermentum
              pellentesque sem interdum. Nunc a rutrum ligula. Maecenas eu
              congue nisl. Morbi lobortis quam a aliquet bibendum. Vestibulum ut
              tortor a turpis commodo ornare.
              <br />
              <br />
              Suspendisse ultrices, tortor sed porta malesuada, ante purus
              rutrum erat, non mollis lacus mauris eget risus. Nunc in feugiat
              lectus, eget commodo risus. Integer ornare sapien in dui
              fringilla, at tincidunt ligula mollis. Donec non congue ex. Nam
              quis nisi nec nisi condimentum aliquam.
            </p>
          </div>
          <button type="button">How to join</button>
        </div>
        <div className="loadmore__congress-container__text">
          <h3>Next Congress</h3>
          <div className="loadmore__congress-container__amsterdam">
            <h1>Amsterdam</h1>
            <div className="loadmore__congress-container__date">
              <p>The 4th Nordic Congress on Gynaecological Endoscopy</p>
              <h6>17 - 19 june 2022</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="loadmore__congress-container">
        <div className="loadmore__congress-container__text">
          <h3>Next Congress</h3>
          <div className="loadmore__congress-container__event">
            <h1>Amsterdam</h1>
            <div className="event__text">
              <p>
                The 4th Nordic Congress on Gynaecological Endoscopy
                <br />
                <br />
                Suspendisse ultrices, tortor sed porta malesuada, ante purus
                rutrum erat, non mollis lacus mauris eget risus. Nunc in feugiat
                lectus, eget commodo risus. Integer ornare sapien in dui
                fringilla, at tincidunt ligula mollis. Donec non congue ex. Nam
                quis nisi nec nisi condimentum aliquam.
              </p>
              <h6>17 - 19 june 2022</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="loadmore__congress-container">
        <div className="loadmore__congress-container__text">
          <h3>Past Congress'</h3>
          <div className="loadmore__congress-container__event">
            <div className="event__past">
              <img src={Helsinki} alt="Helsinki" />
              <div className="event__text2">
                <h6>3rd Nordic Congress</h6>
                <p>5 - 8 June 2019 / Helsinki, Finland</p>
              </div>
            </div>
            <div className="event__past">
              <img src={Norefjell} alt="Norefjell" />
              <div className="event__text2">
                <h6>2nd Nordic Congress</h6>
                <p>8 - 12 March 2017 / Norefjell, Norway</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congress;
