import React from "react";
import "./speakinfo.css";

const SpeakInfo = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Speaker information</h1>
        <h2 className="speakinfo_h2">
          Instructions for PowerPoint presentations
        </h2>
        <p>
          We kindly ask you to follow the instructions to ensure a successful
          and smooth progress of the timetable.
          <br />
          Please hand in your presentation to the technician in the auditorium
          “Tranesalen”. You will find the technician to the right of the stage.
        </p>
        <p>
          Please bring your presentation on a USB stick saved in a version
          compatible with Microsoft Office Home and Business 2019, PowerPoint,
          version 2304 and please make sure the file is with your full name!
          <br />
          Professional technicians will help you to transfer your presentation
          on the provided computer. In order to avoid queues and delays you must
          contact the technicians in the auditorium “Tranesalen”.
          <br />
          <span className="speakinfo_weight">
            Please note that you must submit your presentation to the technician
            on the day of your presentation. Please see the timetable below.
          </span>
        </p>
        <h2 className="speakinfo_h2">
          The technicians will be present in the auditorium “Tranesalen” to
          assist the speakers:
        </h2>
        <ul>
          <li>1 June 2023 from 07:00 - 08:00</li>
          <li>2 June 2023 from 07:00 - 08:00</li>
          <li>3 June 2023 from 07:30 - 08:30</li>
        </ul>
      </div>
    </section>
  );
};

export default SpeakInfo;
