import React from "react";
import "./speakers.css";
import { speakerData } from "../../components/speakerData";

const Speakers = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Speakers</h1>
        <div className="grid__section">
          {speakerData.map((item, index) => (
            <div>
              <div key={index} className="speaker_image_container">
                <div className="title_bio">
                  <div className="img_overflow_container">
                    <img src={item.imageUrl} alt={item.id} />
                  </div>

                  <div className="title_bio_container">
                    <h3>{item.name}</h3>
                    <p>{item.title}</p>
                  </div>
                  <p>{item.bio}</p>
                </div>
              </div>

              <div className="open_abstract">
                <h4>
                  Abstract<p className="speakers__weight">{item.pretext}</p>
                </h4>
                <p>{item.abstract}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Speakers;
