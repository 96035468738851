import React from "react";
import "./footer.css";
import logo from "../../assets/nav-logo.png";

const Footer = () => {
  return (
    <div className="loadmore__footer ">
      <div className="footer__maxWidth section__padding">
        <div className="loadmore__footer-heading">
          <h3>Contact</h3>
          <div className="loadmore__footer-links">
            <div className="loadmore__footer-links__div">
              <h4>President</h4>
              <p>Martin Rudnicki</p>
              <a href="mailto:Martin.rudnicki@rsyd.dk">
                Martin.rudnicki@rsyd.dk
              </a>
            </div>
            <div className="loadmore__footer-links__div">
              <h4>Secretary General</h4>
              <p>Bjarne Rønde Kristensen</p>
              <a href="mailto:Bjarne.Kristensen@rsyd.dk">
                Bjarne.Kristensen@rsyd.dk
              </a>
            </div>
            <div className="loadmore__footer-links__div">
              <h4>Secretary</h4>
              <p>Annette List</p>
              <a href="mailto:Annette.List@rsyd.dk">Annette.list@rsyd.dk</a>
            </div>
          </div>
        </div>
        <div className="loadmore__footer-links__logo">
          <img src={logo} alt="logo" />
          <h1>NCGE-NCE2023</h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
