import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./sidebarData";
import "./sidebar.css";
import SubMenu from "./SubMenu";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const location = useLocation();
  useEffect(() => {
    setSidebar(false);
  }, [location.pathname]);

  return (
    <>
      <div className="sidebarMenuIcon">
        <FaIcons.FaBars className="sidebarIcon" onClick={showSidebar} />
      </div>
      <div className={sidebar ? "centerNav activeCenterNav" : "centerNav"}>
        <div className={sidebar ? "sidebarNav activeSidebar" : "sidebarNav"} />
      </div>
      <div
        className={
          sidebar
            ? "icon padding__inline setActiveSide"
            : "icon padding__inline"
        }
      >
        <AiIcons.AiOutlineClose className="sidebarIcon" onClick={showSidebar} />
      </div>
      <div className={sidebar ? "sidebarWrap setActiveSide" : "sidebarWrap"}>
        {SidebarData.map((item, index) => {
          return <SubMenu item={item} key={index} />;
        })}
      </div>
    </>
  );
};

export default Sidebar;
