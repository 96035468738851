import React from "react";
import finalProgram from "../../assets/Program korr6.pdf";
import "./final.css";

const Final = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Final Programme-printet</h1>
        <div className="final_mobile_message">
          <p>
            If you are on a mobile device, you will have to download the pdf to
            view it proporly
          </p>
          <a
            href="/files/Programkorr6.pdf"
            target="_blank"
            download={"FinalProgram"}
          >
            Click here to download the program
          </a>
        </div>
        <embed src={finalProgram} />
      </div>
    </section>
  );
};

export default Final;
