import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "../sidebar/sidebarData";
import Sidebar from "../sidebar/Sidebar";
import Menu from "../sidebar/Menu";

import "./navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [colorBackground, setColorBackground] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setColorBackground(true);
    } else {
      setColorBackground(false);
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      document.addEventListener("scroll", changeBackground);
      setColorBackground(false);
    } else {
      document.removeEventListener("scroll", changeBackground);
      setColorBackground(true);
    }

    return () => {
      document.removeEventListener("scroll", changeBackground);
    };
  }, [location.pathname]);

  return (
    <>
      <div className="position__navbar">
        <div
          className={
            colorBackground
              ? "loadmore__navbar nav-scrolled"
              : "loadmore__navbar"
          }
        >
          <div className="testClass padding__inline">
            <div className="loadmore__navbar-links">
              <Link
                className={
                  colorBackground
                    ? "loadmore__navbar-links_logo showText"
                    : "loadmore__navbar-links_logo"
                }
                to="/"
              >
                <h1>NCGE-NCE2023</h1>
              </Link>
            </div>
            <div className="loadmore__navbar-links_container">
              {SidebarData.map((item, index) => {
                return <Menu item={item} key={index} />;
              })}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
