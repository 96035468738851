import React from "react";
import "./registration.css";

const Registration = () => {
  return (
    <div className="registration">
      <div className="registration__section section__padding">
        <h1>Registration</h1>
        <div className="registration__section_container">
          <p>Prices are inclusive VAT</p>
          <div className="registration__section_container_table">
            <table>
              <tr>
                <td className="registration__background">
                  <h3>Registration type</h3>
                </td>
                <td className="registration__background">
                  <h3>Early Bird/Euro</h3>
                  <p>31 January - 7 May 2023</p>
                </td>
                <td className="registration__background">
                  <h3>Regular/Euro</h3>
                  <p>8 May - 30 May 2023</p>
                </td>
                <td className="registration__background">
                  <h3>Onsite/Euro</h3>
                  <p>31 May 2023 -</p>
                </td>
                <td className="registration__background">
                  <h3>
                    One-day
                    <br />
                    ticket/Euro
                  </h3>
                  <p>incl. coffee breaks and lunch</p>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Non-members</h2>
                </td>
                <td className="registration__border">
                  <h4>435</h4>
                </td>
                <td className="registration__border">
                  <h4>535</h4>
                </td>
                <td className="registration__border">
                  <h4>585</h4>
                </td>
                <td className="registration__border">
                  <h4>240</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Members</h2>
                </td>
                <td className="registration__border">
                  <h4>385</h4>
                </td>
                <td className="registration__border">
                  <h4>485</h4>
                </td>
                <td className="registration__border">
                  <h4>535</h4>
                </td>
                <td className="registration__border">
                  <h4>240</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Trainees</h2>
                </td>
                <td className="registration__border">
                  <h4>335</h4>
                </td>
                <td className="registration__border">
                  <h4>385</h4>
                </td>
                <td className="registration__border">
                  <h4>435</h4>
                </td>
                <td className="registration__border">
                  <h4>240</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>
                    Young investigators/
                    <br />
                    PHD Students
                  </h2>
                </td>
                <td className="registration__border">
                  <h4>235</h4>
                </td>
                <td className="registration__border">
                  <h4>235</h4>
                </td>
                <td className="registration__border">
                  <h4>285</h4>
                </td>
                <td className="registration__border">
                  <h4>240</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Dinner, 2 June</h2>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
              </tr>
            </table>
          </div>
          <div className="registration__section_container_table">
            <table>
              <tr>
                <td className="registration__background">
                  <h3>Registration type</h3>
                </td>
                <td className="registration__background">
                  <h3>Early Bird/Euro</h3>
                  <p>31 January - 7 May 2023</p>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Non-members</h2>
                </td>
                <td className="registration__border">
                  <h4>435</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Members</h2>
                </td>
                <td className="registration__border">
                  <h4>385</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Trainees</h2>
                </td>
                <td className="registration__border">
                  <h4>335</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>
                    Young investigators/
                    <br />
                    PHD Students
                  </h2>
                </td>
                <td className="registration__border">
                  <h4>235</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Dinner, 2 June</h2>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__background">
                  <h3>Registration type</h3>
                </td>
                <td className="registration__background">
                  <h3>Regular/Euro</h3>
                  <p>8 May - 30 May 2023</p>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Non-members</h2>
                </td>
                <td className="registration__border">
                  <h4>535</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Members</h2>
                </td>
                <td className="registration__border">
                  <h4>485</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Trainees</h2>
                </td>
                <td className="registration__border">
                  <h4>385</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>
                    Young investigators/
                    <br />
                    PHD Students
                  </h2>
                </td>
                <td className="registration__border">
                  <h4>235</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Dinner, 2 June</h2>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__background">
                  <h3>Registration type</h3>
                </td>
                <td className="registration__background">
                  <h3>Onsite/Euro</h3>
                  <p>31 May 2023-</p>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Non-members</h2>
                </td>
                <td className="registration__border">
                  <h4>585</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Members</h2>
                </td>
                <td className="registration__border">
                  <h4>535</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Trainees</h2>
                </td>
                <td className="registration__border">
                  <h4>435</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>
                    Young investigators/
                    <br />
                    PHD Students
                  </h2>
                </td>
                <td className="registration__border">
                  <h4>285</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Dinner, 2 June</h2>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__background">
                  <h3>Registration type</h3>
                </td>
                <td className="registration__background">
                  <h3>
                    One-day
                    <br />
                    ticket/Euro
                  </h3>
                  <p>incl. coffee breaks and lunch</p>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Same price for all registration types</h2>
                </td>
                <td className="registration__border">
                  <h4>240</h4>
                </td>
              </tr>
              <tr>
                <td className="registration__border">
                  <h2>Dinner, 2 June</h2>
                </td>
                <td className="registration__border">
                  <h4>65</h4>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <p className="registration__indent">
          *An administration fee of 12.50 Euro is added to the registration.
        </p>
        <div className="registration__buttonWrap-form">
          <a
            href="https://www.trippus.net/NCGENCE_REG"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to register
          </a>
        </div>
        <p className="registration__indent">
          Please note that your participation in NCGE-NCE2023 automatically
          gives you two years of free membership of Nordic Society of
          Gynaecological Endoscopy (www.NSGE.dk)
          <br />
          <br /> Trainees / Young investigators / PhD students must submit a
          statement signed by their Head of Department or a proof of their
          status on site at the registration desk.
          <br /> All participants and attendants must be registered. The above
          registration fees include entrance to the scientific meetings and
          exhibition area, final programme, name badge, coffee breaks, lunch,
          get together and dinner.
          <br /> All registrations are confirmed upon full payment of the
          registration fees. You can finalize your registration via credit card
          (VISA and MasterCard accepted).
        </p>
        <p className="registration__indent">
          CANCELLATION POLICY
          <br /> All cancellations will be valid if notified in writing to the
          organization secretariat at{" "}
          <a href="mailto:Annette.List@rsyd.dk?subject=NCGE-NCE2023 Registration Cancellation">
            Annette.List@rsyd.dk
          </a>
          .
          <br /> For cancellations received until 31 March, 2023 a subtraction
          of 20 euros will be applied.
          <br /> From 1 April until 1 May, 2023 50% of total amount cancelled
          will be charged.
          <br /> From 2 May 2023 onwards, no cancellation will be accepted and
          there will be no refunds.
          <br /> All refunds will be processed within one month after the
          congress.
        </p>
        <div className="registration__btn_container">
          <div className="registration__buttonWrap-form">
            <a
              href="https://www.trippus.net/NCGENCE_REG"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to register now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
