import React from "react";
import "./general.css";

const General = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>General Information</h1>
        <div className="general_info">
          <h2>Meeting Rooms</h2>
          <p>All sessions is in the auditorium “Tranesalen”.</p>

          <h2>Conference documents and badges -</h2>
          <p>Should be collected on-site, at the registration desk.</p>
          <p>Name badges must be worn during sessions and events.</p>
          <h2>Languages</h2>
          <p>The official conference language is English.</p>
          <h2>Final Programme</h2>
          <p>
            The full program book will be available on the NCGE-NCE2023 website,
            in the printed Final Programme and on the Trippus APP.
          </p>
          <h2>Internet Service</h2>
          <p>
            Free and open Wifi is available for congress participants during the
            venue: HG-Guest
          </p>
          <h2>Mobile phones</h2>
          <p>All mobile phones must be on silent mode during sessions.</p>
          <h2>Meals</h2>
          <ul>
            <li>
              Breakfast for overnight guests at Hindsgavl in the main building,
              between 07:00 - 10:00.
            </li>
            <li>
              Coffee breaks and lunch will be in the exhibition areas, Hall A
              and B.
            </li>
            <li>
              The Get Together 1 June 2023. Meeting point: Outside the entrance
              to the registration desk. Joint departure from Hindsgavl to
              "Søbadet" at 18:45.
            </li>
            <li>
              The dinner will be at Hindsgavl (in the tent behind hall A), 2
              June 2023, at 19:30.
            </li>
          </ul>
          <h2>NSGE General Assembly</h2>
          <p>1 June at 17:45 – 18:15 in the “Tranesalen”.</p>
          <h2>Smoking</h2>
          <p>Smoking is allowed at outdoor areas only.</p>
          <h2>Transport between hotels and venue</h2>
          <p>
            There will be the possibility of transport between Comwell
            Middelfart and Hindsgavl and between Trinity Hotel & Conference
            Center and Hindsgavl.
          </p>
          <h2>Congress App</h2>
          <p>
            All participants have been sent a link to the Congress App, where
            all information can be found.
          </p>
          <h2>Taxi</h2>
          <p>
            You can order a taxi at your own expense via the reception or by
            contacting Taxi Syd by phone +045 64 41 40 55.
          </p>
        </div>
      </div>
    </section>
  );
};

export default General;
