import React from "react";
import { AccomArticle } from "../../components";
import "./accomodation.css";

import Hindsgavl2 from "../../assets/venue01.png";
import Accomodation1 from "../../assets/accomodation1.jpg";
import Accomodation2 from "../../assets/accomodation2.png";
import Accomodation3 from "../../assets/accomodation3.jpg";
import Accomodation4 from "../../assets/accomodation4.png";
import Accomodation5 from "../../assets/accomodation5.png";
import Accomodation6 from "../../assets/accomodation6.jpg";
import Accomodation7 from "../../assets/accomodation7.jpg";

function Accomodation() {
  return (
    <div className="committee__maxWidth">
      <div className="loadmore__committee section__padding">
        <div className="loadmore__committee-heading">
          <h1>Accommodation</h1>
        </div>
        <div className="accomodation__section-container_text">
          <h2>Congress hotels</h2>
          <p>
            There is a separate price for congress participants at these hotels
          </p>
          <h3>
            Please note that the Congress offers transport between Comwell
            Middelfart, Trinity Hotel & Conference Center and the venue
            Hindsgavl Conference Center.
          </h3>
        </div>
        <div className="loadmore__accomodation-container">
          <AccomArticle
            imgUrl={Hindsgavl2}
            title="Hindsgavl Alle 7,
5500 Middelfart"
            name="Hindsgavl Conference Centre"
            titleUnder="Close to Middelfart city and train station but in the middle of the most beautify Danish nature Hindsgavl is the perfect balance between well-equipped facilities, professional planning, and the beautiful Funen summer and nature for walk and talks, networking events etc."
            overBooking="Accommodation in a single room incl. breakfast buffet DKK 1,395.00
For 2 people in a double room, it costs an additional DKK 100"
            booking="
Order per telephone: +45 64 41 88 00 – press 1 
Order per email: hindsgavl@hindsgavl.dk - Please state reservation number 13653
"
            underBooking="ATTENTION! - When booking, a payment link is sent to collect payment for the room."
          />
          <AccomArticle
            imgUrl={Accomodation1}
            title="Karensmindevej 3, 
5500 Middelfart"
            name="Comwell Middelfart"
            titleUnder="Comwell Middelfart is a specialized meeting and conference hotel that is characterized by a warm and welcoming atmosphere.
With its central location in the middle of the country and the easy access from the motorway, Comwell Middelfart is the
obvious choice for meetings, conferences and exhibitions when participants come from all over the country or when accessibility is a priority."
            overBooking="Accommodation in a single room incl. breakfast buffet DKK 1,295.00 
If you want a double room, the price is DKK 1,495.00"
            booking='
Order per telephone: +45 32 72 94 79 press 2 - Please state reservation number 6083633 - booked for Hindsgavl 
Order per email: Booking@comwell.com  - Enter "Comwell Middelfart" in the subject field and please state reservation number 6083633 - booked for Hindsgavl 
'
          />
          <AccomArticle
            imgUrl={Accomodation2}
            title="Gl Færgevej 30, 7000 Fredericia 
"
            name="Trinity Hotel & Conference Center"
            titleUnder="Spacious hotel rooms, many of which have a view
- a large breakfast buffet is a matter of course at Trinity."
            overBooking="Accommodation in a single room incl. breakfast buffet per night DKK 1,190.00 "
            booking="
Order per telephone: +45 82 27 17 17 - Please state reservation number 626234 - booked for Hindsgavl Order per email: rec@trinity.dk - Please state reservation number 626234 - booked for Hindsgavl"
          />
        </div>
        <div className="venue__section-container_text">
          <h2>Other accommodation options</h2>
        </div>
        <div className="loadmore__accomodation-container">
          <AccomArticle
            imgUrl={Accomodation3}
            title="Hindsgavl Allé 2, 5500 Middelfart"
            name="Huset, Middelfart"
            titleUnder="HUSET Middelfart has a total of 70 bright, beautiful and handicap-friendly rooms - all with private bath and toilet, TV and exit to green area."
            booking={
              <a
                href="https://huset-middelfart.dk/vaerelser/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking of: Accommodation
              </a>
            }
          />
          <AccomArticle
            imgUrl={Accomodation4}
            title="Kongebrovej 63,
5500 Middelfart"
            name="Comwell Kongebrogården"
            titleUnder="Comwell Kongebrogaarden is a cosy luxury hotel, right down to Lillebælt on Funen. An idyllic stay at the waterfront surrounded by forest. "
            booking={
              <a
                href="https://comwell.com/en/hoteller/comwell-hotel-kongebrogaarden?_gl=1*1btf8l3*_up*MQ..&gclid=EAIaIQobChMI4_n-o5zx_AIV95BoCR1u0gSSEAAYASAAEgKlvPD_BwE"
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking of: Accommodation
              </a>
            }
          />
          <AccomArticle
            imgUrl={Accomodation7}
            title="
Teglgårdsvej 73
5500 Middelfart"
            name="Sixtus Sinatur Hotel & Konference"
            titleUnder="Welcome to Sixtus, 
            luxury at the waters edge."
            overBooking="Could a hotel have a better location? In the cozy Funen town of Middelfart, on the shores of Fænø Sound, lies Sixtus Sinatur Hotel & Conference Centre with invigorating and inspiring views. Complement your stay with a gastronomic experience in our restaurant, where our chefs use locally sourced and often organic ingredients."
            booking={
              <a
                href="https://www.sinatur-hotels.com/sixtus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking of: Accommodation
              </a>
            }
          />
          <AccomArticle
            imgUrl={Accomodation5}
            title="Viaduktvej 28, 5500 Middelfart"
            name="Milling Hotel Park"
            titleUnder="Milling Hotel Park in Middelfart is located near the train station, right in the lively city centre and not far from the waterfront and nature on Funen’s west coast."
            booking={
              <a
                href="https://millinghotels.dk/hoteller/milling-hotel-park/?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking of: Accommodation
              </a>
            }
          />
          <AccomArticle
            imgUrl={Accomodation6}
            title="Vestre Ringvej 98A, 7000 Fredericia"
            name="Danhostel Fredericia"
            booking={
              <a
                href="https://fredericia-danhostel.dk/?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Booking of: Accommodation
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Accomodation;
