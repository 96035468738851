import React from "react";
import "./spons.css";
import Normedi from "../../assets/spons_normedi.png";
import Medtronic from "../../assets/exhibitors_Medtronic.png";
import MarkMedicaroid from "../../assets/spons_MarkMedicaroid.png";
import Olympus from "../../assets/spons_olympus.png";
import Cast from "../../assets/spons_cast.jpg";
import Ferdinand from "../../assets/spons_ferdinand.jpg";
import Intuitive from "../../assets/exhibitors_intuitive.svg";
import Applied from "../../assets/exhibitors_appliedmedical.png";

const Spons = () => {
  return (
    <section>
      <div className="spons__container section__padding">
        <h1>Sponsors</h1>
        <div className="spons_mini_container">
          <h2 className="platinum">Platinum Sponsors</h2>
          <div className="platinum_grid_container">
            <a
              href="https://www.medtronic.com/dk-da/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Medtronic} alt="Medtronic" />
            </a>
            <a
              href="https://www.intuitive.com/en-gb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Intuitive} alt="Intuitive" />
            </a>
          </div>
          <h2 className="gold">Gold Sponsors</h2>
          <div className="gold_grid_container">
            <a
              href="http://www.normedi.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Normedi} alt="Normedi" />
            </a>
          </div>
          <h2 className="bronze">Bronze Sponsors</h2>
          <div className="bronze_grid_container">
            <a
              href="https://www.olympus.dk/medical/en/Products-and-Solutions/Products/Product/VISERA-ELITE-III.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Olympus} alt="Olympus" />
            </a>
            <a
              href="mailto:CAST.Europe@Castsurgical.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Cast} alt="Cast" />
            </a>
            <a
              href="http://www.ferdinandmedical.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="spons_bigger"
            >
              <img src={Ferdinand} alt="Ferdinand" />
            </a>
          </div>
          <h2 className="additional">Additional Sponsors</h2>
          <div className="additional_grid_container">
            <div>
              <a
                href="https://www.medicaroid.com/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={MarkMedicaroid} alt="MarkMedicaroid" />
              </a>
              <h3>
                <br />
                Sponsored Coffee Break:
                <br />
                <br />1 June 2023, 15:30 – 16:00
              </h3>
            </div>
            <div>
              <a
                href="https://www.appliedmedical.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Applied} alt="Applied" />
              </a>
              <h3>
                <br />
                Sponsored Symposium: vNOTES hysterectomy: benefits, current
                evidence and implementation at Helsingborg hospital, Sweden.
                <br />
                <br />3 June 2023, 09:00 – 09:30
                <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Spons;
