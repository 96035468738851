import React from "react";
import { Article } from "../../components";
import {
  blog01,
  blog02,
  blog03,
  blog04,
  blog07,
  blog08,
  blog10,
  blog11,
  blog19,
} from "./imports";
import "./scientific.css";

function Organizing() {
  return (
    <div className="committee__maxWidth">
      <div className="loadmore__committee section__padding">
        <div className="loadmore__committee-heading">
          <h1>Organizing Committee</h1>
        </div>
        <div className="loadmore__committee-container">
          <Article
            imgUrl={blog02}
            title="Chairman Organizing Committee"
            name="Bjarne Rønde Kristensen"
            titleUnder="Chief Consultant, Associated Professor, MD
Department of Gynecology and Obstetrics
OUH Odense University Hospital"
          />
          <Article
            imgUrl={blog01}
            title="Congress President"
            name="Martin Rudnicki"
            titleUnder="Professor, Head of Minimal
Invasive Gynecological Surgery
Department of Gynecology and Obstetrics
OUH Odense University Hospital"
          />
          <Article
            imgUrl={blog19}
            title="Committee Member"
            name="Annemette Wildfang Lykkebo"
            titleUnder="Head of Department
Department of Gynecology and Obstetrics
OUH Odense University Hospital"
          />
          <Article
            imgUrl={blog10}
            title="Committee Member"
            name="Torben Munk"
            titleUnder="Chief Consultant
Department of Gynecology and Obstetrics
OUH Odense University Hospital"
          />
          <Article
            imgUrl={blog04}
            title="Committee Member"
            name="Vibeke Lysdal"
            titleUnder="Consultant
Department of Gynecology and Obstetrics OUH Odense University Hospital"
          />
          <Article
            imgUrl={blog07}
            title="Committee Member"
            name="Bente Bækholm Poulsen"
            titleUnder="Consultant
Department of Gynecology and Obstetrics
Aarhus University Hospital"
          />
          <Article
            imgUrl={blog03}
            title="Committee Member"
            name="Sigurd Beier Sloth"
            titleUnder="MD, Ph.d.,
Department of Obstetrics and Gynaecology
Randers Regional Hospital"
          />
          <Article
            imgUrl={blog08}
            title="Committee Member"
            name="Sarah Camilla Bernice Jeppesen"
            titleUnder="Registrar
Department of Gynecology and Obstetrics OUH Odense University Hospital"
          />
        </div>
      </div>
      <div className="loadmore__committee section__padding">
        <div className="loadmore__committee-heading">
          <h3>Secretariat</h3>
        </div>
        <div className="loadmore__committee-container">
          <Article
            imgUrl={blog11}
            title="Congress Secretary"
            name="Annette List"
            titleUnder={"Annette.List@rsyd.dk"}
          />
        </div>
      </div>
    </div>
  );
}

export default Organizing;
