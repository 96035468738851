import React from "react";
import "./posters.css";

const Posters = () => {
  return (
    <section>
      <div className="posters__container section__padding">
        <h1>Selected abstracts</h1>
        <table>
          <tr>
            <td>
              Use of PROdata for evaluation of postoperative pain in vomen with
              endometriosis
            </td>
            <td>Lillesø, Emma & Ejrnæs, Louise</td>
          </tr>
          <tr className="posters_color">
            <td>
              Diagnosis and management of postoperatively detected ureteric
              injuries following surgery for deep infiltrating endometriosis
            </td>
            <td>Egekvist, Anne G.</td>
          </tr>
          <tr>
            <td>INNOVATION IN LAPAROSCOPIC ENTRY: LEVALAP 1.0</td>
            <td>Thurkow, Andreas</td>
          </tr>
          <tr className="posters_color">
            <td>
              CASE REPORT: Uterus inversion and re-inversion - laparoscopic
              repositioning
            </td>
            <td>Michelsen-Wahl, Henrik</td>
          </tr>
          <tr>
            <td>
              Comparison of recurrency following laparoscopic or vaginal
              uterosacral ligament suspension
            </td>
            <td>Nielsen, Julia Mantzius</td>
          </tr>
          <tr className="posters_color">
            <td>
              The Danish Vaginal Vault (DIVA) study - Operation of choice, risk
              of recurrence and quality of life
            </td>
            <td>Teilmann-Jørgensen, Dorte</td>
          </tr>
          <tr>
            <td>
              Common practise of laparoscopic entry and fascial closure in the
              Netherlands.
            </td>
            <td>Veersema, Sebastiaan</td>
          </tr>
          <tr className="posters_color">
            <td>
              Essure related symptoms, a nationwide, multicenter prospective
              cohort study
            </td>
            <td>van Gastel, Daniëlle M.</td>
          </tr>
          <tr>
            <td>Chatbot for patients with endometriosis</td>
            <td>Christiansen, Rikke Vestesen</td>
          </tr>
          <tr className="posters_color">
            <td>
              The effects of a digital endometriosis self-management program?
              Protocol for a three-armed RCT.
            </td>
            <td>Hansen, Karina Ejgaard</td>
          </tr>
          <tr>
            <td>
              Prevalence of endometriosis in diagnostic and therapeutic
              laparoscopy among women with chronic pelvic pain.
            </td>
            <td>Suvitie, Pia A.</td>
          </tr>
          <tr className="posters_color">
            <td>
              Is Intra-Cesarean myomectomy a safe and practical procedure?
            </td>
            <td>Schwarzman, Polina</td>
          </tr>
          <tr>
            <td>
              Validation of the ICD-10 endometriosis diagnosis in the Danish
              National Patient Registry
            </td>
            <td>Thomsen, Line Holdgaard</td>
          </tr>
          <tr className="posters_color">
            <td>
              ARE SURGICAL TRAINEES READY TO PERFORM SURGERY INDEPENDENTLY? THE
              SUPERVISORS' PERSPECTIVES: A SCOPING REVIEW
            </td>
            <td>Hesseldal, Karen Busk</td>
          </tr>
          <tr>
            <td>
              Longterm effects of endometrial resection or ablation in
              combination with levonorgestrel intrauterine device on bleeding
              patterns
            </td>
            <td>Straarup, Signe Engholm</td>
          </tr>
          <tr className="posters_color">
            <td>A PILOT STUDY: SINGLE CELL TRANSCRIPTOMICS IN ENDOMETRIOSIS</td>
            <td>Olesen, Mia Steengaard</td>
          </tr>
          <tr>
            <td>
              Technical, Non-Technical, or Both? A Scoping Review of Skills in
              Simulation-Based Surgical Training
            </td>
            <td>Rosendal, Amalie Asmind</td>
          </tr>
          <tr className="posters_color">
            <td>
              Expression of cancer driver genes in endometriosis: A systematic
              review
            </td>
            <td>Skou, Helene Greve</td>
          </tr>
          <tr>
            <td>
              Tele-patient-reported outcome measures in endomtriosis follow-up
              of patients. The PROMise study
            </td>
            <td>Feenstra, Maria Monberg</td>
          </tr>
          <tr className="posters_color">
            <td>
              Abdominal Wall Endometriosis After Caesarian Section: A diagnostic
              Challenge - Two Case Reports
            </td>
            <td>Justesen, Louise Pilegaard</td>
          </tr>
          <tr>
            <td>Ovarian torsion in pregnancy at 32 weeks: A case report</td>
            <td>Itkonen, Anna-Maija Freitas</td>
          </tr>
          <tr className="posters_color">
            <td>
              Magnetic Resonance-guided High Intensity Focused Ultrasound
              (MR-HIFU) - Incision-free Therapy for Adenomyosis and Uterine
              Fibroids
            </td>
            <td>Yeo, Sin Yuin</td>
          </tr>
        </table>
      </div>
    </section>
  );
};

export default Posters;
