import React from "react";
import "./programme.css";
import { useInView } from "react-intersection-observer";
import { BsArrowUpCircleFill, BsArrowDownCircleFill } from "react-icons/bs";

const Programme = () => {
  const { ref: saturday, inView: saturdayInView } = useInView({
    threshold: 0.3,
  });
  const { ref: friday, inView: fridayInView } = useInView({ threshold: 0.3 });
  const { ref: thursday, inView: thursdayInView } = useInView({
    threshold: 0.3,
  });

  let counter = null;

  if (saturdayInView === true) {
    counter = 3;
  } else if (fridayInView === true) {
    counter = 2;
  } else if (thursdayInView === true) {
    counter = 1;
  }

  console.log("saturday", saturdayInView);
  console.log("counter", counter);

  function down() {
    if (counter <= 2) {
      counter = counter + 1;
      document.getElementById(counter).scrollIntoView();
      console.log("counter", counter);
    }
  }
  function up() {
    if (counter >= 2) {
      counter = counter - 1;
      document.getElementById(counter).scrollIntoView();
      console.log("counter", counter);
    }
  }

  return (
    <div className="loadmore__programme section__padding">
      <div className="sticky-button">
        <div className="buttonNav">
          <BsArrowUpCircleFill
            className="programme__button-icon"
            onClick={up}
          />
          <BsArrowDownCircleFill
            className="programme__button-icon"
            onClick={down}
          />
        </div>
      </div>
      <div className="loadmore__programme-section">
        <h2 id="1">Scientific Programme</h2>

        <div ref={thursday} className="loadmore__programme-section__table">
          <h3>Thursday, 1 June 2023</h3>

          <table>
            <tr>
              <td className="right">08:00 - 18:00</td>
              <td>Registration</td>
            </tr>
            <tr>
              <td className="right">09:00 - 09:10</td>
              <td>
                Welcome and Opening Ceremony Presidential welcome: Martin
                Rudnicki
              </td>
            </tr>
          </table>

          <h5>Nordic Endometriosis Session</h5>

          <table>
            <tr>
              <td className="right programme__weight">09:15 - 10:30</td>
              <td className="programme__weight">
                Endometriosis <br /> Chairs: Päivi Härkki, FI and Torben Munk,
                DK
              </td>
            </tr>
            <tr>
              <td className="right">09:15 - 09:35</td>
              <td>
                From genes to mechanism in endometriosis <br /> Mette Nyegaard,
                DK
              </td>
            </tr>
            <tr>
              <td className="right">09:35 - 09:45</td>
              <td>
                Prevalence of endometriosis in diagnostic and therapeutic
                laparoscopy among women with chronic pelvic pain. <br />
                Pia Suvitie, F
              </td>
            </tr>
            <tr>
              <td className="right">09:45 - 10:00</td>
              <td>
                New ESHRE Endometriosis Guideline: what is new?
                <br />
                Ertan Saridogan, GB
              </td>
            </tr>
            <tr>
              <td className="right">10:00 - 10:15</td>
              <td>
                Finding endometriosis using Machine Learning
                <br /> Ulrik Bak Kirk, DK
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">10:15 – 11:00</td>
              <td className="cursive">Coffee break & exhibition</td>
            </tr>
            <tr>
              <td className="right cursive">10:30 – 11:00</td>
              <td className="cursive">
                Sponsored Symposium - CMR Surgical Ltd.
                <br />
                Surgical Statistics- A cautionary tale
                <br />
                Mark Slack, UK
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">11:00 - 12:30</td>
              <td className="programme__weight">
                Genetics and future therapy in endometriosis
                <br />
                Chairs: Martin Rudnicki, DK and Jenny Alvirovic, NO
              </td>
            </tr>
            <tr>
              <td className="right">11:00 - 11:20</td>
              <td>
                Surgery for DE - Long term outcomes, complications and sequelae
                <br />
                Gernot Hudelist, Austria - online
              </td>
            </tr>
            <tr>
              <td className="right">11:20 - 11:40</td>
              <td>
                Scoring systems of ovarian cyst – what to use? (IOTA score and
                ADNEX model)
                <br /> Lil Valentin, SE - online
              </td>
            </tr>
            <tr>
              <td className="right">11:40 - 11:55</td>
              <td>
                Adenomyosis – an update
                <br />
                Margit Dueholm, DK
              </td>
            </tr>
            <tr>
              <td className="right">11:55 - 12:10</td>
              <td>
                Robotic vs laparoscopic surgery – how to choose?
                <br />
                Mohamed Mabrouk, GB
              </td>
            </tr>
            <tr>
              <td className="right">12:10 - 12:30</td>
              <td>
                Bowel endometriosis surgery: what to do?
                <br /> Mohamed Mabrouk, GB
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">12:30 – 13:30</td>
              <td className="cursive">Lunch & exhibition</td>
            </tr>
            <tr>
              <td className="right cursive">12:45 – 13:30</td>
              <td className="cursive">
                Sponsored Symposium - Intuitive Surgical Sàrl
                <br />
                Treating endometriosis – why go from lap to Da Vinci? –Our
                experience in establishing robotic assisted surgery at Ullevål
                Hospital Dr Guri Majak, NO
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">13:30 - 15:30</td>
              <td className="programme__weight">
                Challenges in relation to invasive endometriosis –the knowledge
                of anatomic landmarks
                <br />
                Chairs: Asgeir Thoroddsen, IS and Stine Andreasen, NO
              </td>
            </tr>
            <tr>
              <td className="right">13:30 - 13:50</td>
              <td>
                State of the art surgery in endometriosis
                <br /> Dorthe Hartwell
              </td>
            </tr>
            <tr>
              <td className="right">13:50 - 14:10</td>
              <td>
                Medical treatment versus surgery?
                <br />
                Paulo Vercelli, IT
              </td>
            </tr>
            <tr>
              <td className="right">14:10 - 14:30</td>
              <td>
                Urinary tract endometriosis
                <br /> Päivi Härkki, FI
              </td>
            </tr>
            <tr>
              <td className="right">14:30 - 14:45</td>
              <td>
                How to repair bladder /ureter injuries
                <br /> Jørgen Bjerggaard Jensen, DK
              </td>
            </tr>
            <tr>
              <td className="right">14:45 - 15:05</td>
              <td>
                Bowel resection and/or shaving
                <br /> Jon Einarsson, USA - online
              </td>
            </tr>
            <tr>
              <td className="right">15:05 - 15:20</td>
              <td>
                Is endometriosis a recurrent disease?
                <br /> Mikkel Seyer Hansen, DK
              </td>
            </tr>
            <tr>
              <td className="right">15:20 - 15:30</td>
              <td>Panel discussion</td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">15:30 – 16:00</td>
              <td className="cursive">
                Coffee break & exhibition
                <br />
                Sponsored Coffee break - Medicaroid Europe GmbH
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">16:00 - 17:45</td>
              <td className="programme__weight">
                Surgical training
                <br />
                Chairs: Sigurd Sloth, DK and Stine Andreasen, NO
              </td>
            </tr>
            <tr>
              <td className="right">16:00 - 16:20</td>
              <td>
                Transfer of surgical training
                <br />
                Sigurd Sloth, DK
              </td>
            </tr>
            <tr>
              <td className="right">16:20 - 16:30</td>
              <td>
                Are surgical trainees ready to perform surgery independently?
                The supervisors' perspectives: A scoping review
                <br />
                Karen Busk Hesseldal, DK
              </td>
            </tr>
            <tr>
              <td className="right">16:30 - 16:50</td>
              <td>
                Interprofessional team training in surgery
                <br />
                Rune Dall Jensen, DK
              </td>
            </tr>
            <tr>
              <td className="right">16:50 - 17:05</td>
              <td>
                Simulation training; proven effect, tips and tricks
                <br /> Ewa Hovi, FI
              </td>
            </tr>
            <tr>
              <td className="right">17:05 - 17:20</td>
              <td>
                Human factors / non-technical factors
                <br /> Liv Karlsson-Ahlborg, SE
              </td>
            </tr>
            <tr>
              <td className="right">17:20 - 17:35</td>
              <td>
                How to train on robotic surgery
                <br /> Ebbe Thinggaard, DK
              </td>
            </tr>
            <tr>
              <td className="right">17:35 - 17:45</td>
              <td>Panel discussion</td>
            </tr>
            <tr>
              <td className="right programme__weight">17:45 - 18:15</td>
              <td className="programme__weight">NSGE General Assembly</td>
            </tr>
          </table>

          <table id="2">
            <tr>
              <td className="right cursive">19:00 - 21:00</td>
              <td className="cursive">
                Get together - Meeting point: Outside the entrance to the
                registration desk.
                <br />
                Joint departure from Hindsgavl to "Søbadet" at 18:45.
              </td>
            </tr>
          </table>
        </div>

        <div ref={friday} className="loadmore__programme-section__table">
          <h3>Friday, 2 June 2023</h3>

          <table>
            <tr>
              <td className="right">08:00 - 18:00</td>
              <td>Registration</td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="right cursive">08:20 - 08:50</td>
              <td className="cursive">
                Sponsored symposium – Cast Core Surgical Technologies Inc.
                <br />
                Our First Real Life Results in 15 Cases in OUH done by Martin
                Rudnicki and scientific
                <br />
                background of the LevaLap 1.0.
                <br />
                Dr. Vibeke Kramer Lysdal, DK and Dr. Andreas Thurkow, NL
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="right programme__weight">09:00 - 10:30</td>
              <td className="programme__weight">
                Surgery in pregnancy
                <br />
                Chairs: Päivi Härkki, FI and Anna Kraen, SE
              </td>
            </tr>
            <tr>
              <td className="right">09:00 - 09:20</td>
              <td>
                Abdominal surgery during pregnancy, implications for ongoing
                pregnancy
                <br /> Niels Uldbjerg, DK
              </td>
            </tr>
            <tr>
              <td className="right">09:20 - 09:30</td>
              <td>
                Is Intra-Cesarean myomectomy a safe and practical procedure?
                <br />
                Polina Schwarzman, IS
              </td>
            </tr>
            <tr>
              <td className="right">09:30 - 09:50</td>
              <td>
                Anesthesiologic tips and tricks during pregnancy
                <br /> Heidi Kallela, FI
              </td>
            </tr>
            <tr>
              <td className="right">09:50 - 10:05</td>
              <td>
                Limits of laparoscopy during pregnancy – tips and tricks
                <br /> Pia Suvitie, FI
              </td>
            </tr>
            <tr>
              <td className="right">10:05 - 10:20</td>
              <td>
                Robotic surgery during pregnancy?
                <br /> Jan Persson, SE - pre-recorded
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">10:20 - 11:15</td>
              <td className="cursive">Coffee break & exhibition</td>
            </tr>
            <tr>
              <td className="right cursive">10:30 - 11:15</td>
              <td className="cursive">
                Sponsored Symposium - Normedi:
                <br />
                The impact of evidence-based data for Novasure endometrial
                ablation
                <br /> Marlies Bongers and Peggy Geomini, NL
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">11:15 - 12:30</td>
              <td className="programme__weight">
                Uterine malformation – state of the art
                <br />
                Chairs: Pia Suvitie, F and Torben Munk, DK
              </td>
            </tr>
            <tr>
              <td className="right">11:00 - 11:30</td>
              <td>
                Uterine malformation
                <br /> Kirsten Hald, NO
              </td>
            </tr>
            <tr>
              <td className="right">11:30 - 11:55</td>
              <td>
                Asherman syndrome, how to prevent and treat
                <br />
                Miriam Hanstede, NL
              </td>
            </tr>
            <tr>
              <td className="right">11:55 - 12:15</td>
              <td>
                Septate uterus, impact on fertility
                <br />
                Miriam Hanstede, NL
              </td>
            </tr>
            <tr>
              <td className="right">12:15 - 12:30</td>
              <td>
                Sustainability in gynaecology
                <br /> Wouter Hehenkamp, NL
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">12:30 - 13:45</td>
              <td className="cursive">Lunch & exhibition</td>
            </tr>
            <tr>
              <td className="right cursive">13:00 - 13:45</td>
              <td className="cursive">
                Sponsored Symposium - MEDTRONIC Danmark A/S <br />
                Introducing HUGO, start up experiences from Hvidovre Hospital,
                Spring 2023
                <br /> Dr Kristine Juul Hare & Dr Anne Katrine Holflod Friberg
                from Amager og Hvidovre Hospital, DK – online
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">13:45 - 15:30</td>
              <td className="programme__weight">
                Myoma
                <br />
                Chairs: Bjarne Rønde Kristensen, DK and Wouter Hehenkamp, NL
              </td>
            </tr>
            <tr>
              <td className="right">13:45 - 14:05</td>
              <td>
                Minimally invasive treatment for fibroids
                <br /> Wouter Hehenkamp, NL
              </td>
            </tr>
            <tr>
              <td className="right">14:05 - 14:25</td>
              <td>
                Office hysteroscopy - how far can we get?
                <br /> Ursula Catena, IT - online
              </td>
            </tr>
            <tr>
              <td className="right">14:25 - 14:45</td>
              <td>
                The big uterus
                <br /> Jon Einarsson, USA - online
              </td>
            </tr>
            <tr>
              <td className="right">14:45 - 15:00</td>
              <td>
                Impact of myoma on the endometrium and future therapy
                <br /> Bente Bækholm Poulsen, DK
              </td>
            </tr>
            <tr>
              <td className="right">15:00 - 15:15</td>
              <td>
                Pelvic sidewall anatomy - state of the art
                <br /> Algirdas Markauskas, DK
              </td>
            </tr>
            <tr>
              <td className="right">15:15 - 15:20</td>
              <td>Panel discussion</td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">15:20 - 16:00</td>
              <td className="cursive">Coffee break/exhibition</td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">16:00 - 17:30</td>
              <td className="programme__weight">
                Urogyncology
                <br />
                Chairs: Dorte Teilmann-Jørgensen, DK and Niels Klarskov, DK
              </td>
            </tr>
            <tr>
              <td className="right">16:00 - 16:20</td>
              <td>
                Advances in apical support
                <br /> Maarten Vink, NL
              </td>
            </tr>
            <tr>
              <td className="right">16:20 - 16:30</td>
              <td>
                Comparison of recurrency following laparoscopic or vaginal
                uterosacral ligament suspension.
                <br />
                Julia Mantzius Nielsen, DK
              </td>
            </tr>
            <tr>
              <td className="right">16:30 - 16:45</td>
              <td>
                The Manchester Procedure – the return of the oldest minimal
                invasive POP procedure
                <br /> Niels klarskov, DK
              </td>
            </tr>
            <tr>
              <td className="right">16:45 - 17:05</td>
              <td>
                Laparoscopic removal of suprapubic slings
                <br /> Jenny Alvirovic, N
              </td>
            </tr>
            <tr>
              <td className="right">17:05 - 17:20</td>
              <td>
                How to introduce new surgical techniques – lessons learned from
                the introduction of vaginal mesh
                <br /> Niels Klarskov, DK
              </td>
            </tr>
            <tr>
              <td className="right">17:20 - 17:30</td>
              <td>Panel discussion</td>
            </tr>
          </table>

          <table id="3">
            <tr>
              <td className="right cursive">19:30</td>
              <td className="cursive">
                Dinner - Welcome drink on the terrace facing the sea
              </td>
            </tr>
          </table>
        </div>

        <div ref={saturday} className="loadmore__programme-section__table">
          <h3>Saturday, 3 June 2023</h3>
          <table>
            <tr>
              <td className="right cursive">09:00 - 09:30</td>
              <td className="cursive">
                Sponsored Symposium - Applied Medical Danmark ApS:
                <br />
                vNOTES hysterectomy: benefits, current evidence and
                implementation at Helsingborg hospital, Sweden.
                <br />
                Andrea Stuart - Sektionschef gynekologi, Överläkare,
                Helsingborg, Sweden and
                <br />
                Johanna Wagenius - Verksamhetschef, överläkare, VO Obstetrik och
                Gynekologi, Helsingborg, Sweden
              </td>
            </tr>
            <tr>
              <td className="right ">09:00 - 09:30</td>
              <td>
                Walk and talk - meeting point at the registration desk
                <br />
                Scientific writing – how can I get started?
                <br />
                How to write a paper?
                <br />
                How to find time?
                <br />
                PhD session
              </td>
            </tr>
            <tr>
              <td className="right ">09:30 - 09:40</td>
              <td>Presidential Address</td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">09:40 - 10:25</td>
              <td className="programme__weight">
                Complications in relation to surgery
                <br />
                Chairs: Asgeir Thoroddsen, IS and Martin Rudnicki, DK
              </td>
            </tr>
            <tr>
              <td className="right">09:40 - 10:00</td>
              <td>
                Complications and how to manage during robotic surgery
                <br /> Jan Persson, SE - online
              </td>
            </tr>
            <tr>
              <td className="right">10:00 - 10:20</td>
              <td>
                Morbidity following robotic-assisted surgery
                <br /> Algirdas Makaukas, DK
              </td>
            </tr>
            <tr>
              <td className="right">10:20 - 10:25</td>
              <td>
                Next congress 2025 Iceland by Asgeir Thoroddsen
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">10:25 - 10:55</td>
              <td className="cursive">Coffee break/exhibition</td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right programme__weight">10:55 - 12:00</td>
              <td className="programme__weight">
                Ovarian cyst– when and how to treat
                <br />
                Chairs: Anna Kraen, S and Vibeke Kramer Lysdal, DK
              </td>
            </tr>
            <tr>
              <td className="right">10:55 - 11:10</td>
              <td>
                Neuropelveology/endometriosis on the ischias nerve<br/>
                Axel Forman, DK
              </td>
            </tr>
            <tr>
              <td className="right">11:10 - 11:25</td>
              <td>
                Ultrasound image analysis for discriminating between benign and
                malignant ovarian tumors
                <br /> Elisabeth Epstein, SE
              </td>
            </tr>
            <tr>
              <td className="right">11:25 - 11:35</td>
              <td>
                Common practise of laparoscopic entry and fascial closure in the
                Netherlands.
                <br />
                Sebastiaan Veersema, NL
              </td>
            </tr>
            <tr>
              <td className="right">11:35 - 11:50</td>
              <td>
                Endometrioma - a case
                <br /> Martin Rudnicki, DK
              </td>
            </tr>
            <tr>
              <td className="right">11:50 - 12:00</td>
              <td>Panel discussion</td>
            </tr>
          </table>

          <table>
            <tr>
              <td className="right cursive">12:00</td>
              <td className="cursive">
                Closing Ceremony
                <br />
                Awarding the best abstract/movie/poster
                <br />
                Next congress 2025 Iceland by Asgeir Thoroddsen
                <br />
                Closing lecture/words
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Programme;
